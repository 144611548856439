import { Link } from "react-router-dom";

export const DisabledAccount = () => {
  return (
    <section className="disable">
      <div>
        <h3 className="heading-tertiary">Your account has been disabled</h3>
        <h4>Why your account may be disabled</h4>
        <ul>
          <li>Your activities on the site are suspicious</li>
          <li>The system detects you are just playing around</li>
        </ul>
        <small>
          If you believe your account is wrongly blocked <br />
          <Link to="/support" className="cta">
            contact support
          </Link>
        </small>
      </div>
    </section>
  );
};
