import { useState, useEffect } from "react";
import "./styles/sass/main.scss";
import Home from "./pages/Home";
import AuthHome from "./pages/account/AuthHome";
import PasswordReset from "./pages/account/PasswordReset";
import UserHome from "./pages/user/UserHome";
import Profile from "./pages/user/Profile";
import Category from "./pages/Category";
import Affidavit from "./pages/Affidavit";
import Document from "./pages/Document";
import Success from "./pages/Refs";
import { SupportAndIssueForm } from "./pages/user/SupportAndIssueForm";
import { Layout } from "./component/Layout";
import PrivateRoute from "./protected/PrivateRoute";
import NotFound from "./pages/NotFound";
import { useGlobalContext } from "./util/context";
import { Routes, Route } from "react-router-dom";
import icon from "./images/favicon.ico";
import { TermsAndConditions } from "./pages/TermsAndConditions";
import { Privacy } from "./pages/Privacy";

// Hook to detect if the developer tools are open
const useDetectDevTools = () => {
  const [isDevToolsOpen, setIsDevToolsOpen] = useState(false);

  useEffect(() => {
    const isDesktop = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobile = /mobile|android|iphone|ipad|phone/i.test(userAgent);
      const isSmallScreen = window.innerWidth < 768;

      // Return true if it's not a mobile device and the screen width is large
      return !isMobile && !isSmallScreen;
    };

    if (!isDesktop()) {
      return; // Exit early if not a desktop device
    }

    const threshold = 160; // Adjust threshold as necessary
    const checkDevTools = () => {
      const widthThreshold = window.outerWidth - window.innerWidth > threshold;
      const heightThreshold =
        window.outerHeight - window.innerHeight > threshold;
      const devToolsOpened = widthThreshold || heightThreshold;

      setIsDevToolsOpen(devToolsOpened);
    };

    const interval = setInterval(checkDevTools, 1000);

    return () => clearInterval(interval);
  }, []);

  return isDevToolsOpen;
};

// Component to prevent right-clicking
// const PreventRightClick = () => {
//   useEffect(() => {
//     const handleContextMenu = (event) => {
//       event.preventDefault();
//     };

//     const handleKeyDown = (event) => {
//       const keyCode = event.keyCode || event.which;
//       const ctrlShift = event.ctrlKey && event.shiftKey;

//       // Disable common shortcuts for opening developer tools
//       if (
//         keyCode === 123 || // F12
//         (ctrlShift && keyCode === 73) || // Ctrl+Shift+I
//         (ctrlShift && keyCode === 74) || // Ctrl+Shift+J
//         (ctrlShift && keyCode === 67) || // Ctrl+Shift+C
//         (event.ctrlKey && keyCode === 85) // Ctrl+U (view source)
//       ) {
//         event.preventDefault();
//         event.stopPropagation();
//       }
//     };

//     const handleCopyCut = (event) => {
//       event.preventDefault();
//     };

//     document.addEventListener("contextmenu", handleContextMenu);
//     document.addEventListener("keydown", handleKeyDown);
//     document.addEventListener("copy", handleCopyCut);
//     document.addEventListener("cut", handleCopyCut);

//     return () => {
//       document.removeEventListener("contextmenu", handleContextMenu);
//       document.removeEventListener("keydown", handleKeyDown);
//       document.removeEventListener("copy", handleCopyCut);
//       document.removeEventListener("cut", handleCopyCut);
//     };
//   }, []);

//   return null;
// };

function App() {
  const { isAuthenticated } = useGlobalContext();
  useEffect(() => {
    const favicon = document.getElementById("favicon");
    favicon.setAttribute("href", icon);
  }, []);
  const isDevToolsOpen = useDetectDevTools();

  return (
    <div className="App">
      {!isDevToolsOpen ? (
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route
            exact
            path="/account"
            element={
              <Layout showHeader={false} showFooter={false}>
                <AuthHome />
              </Layout>
            }
          />
          <Route
            exact
            path="/account/reset-password"
            element={
              <Layout>
                <PasswordReset />
              </Layout>
            }
          />
          <Route
            exact
            path="/user"
            element={
              <Layout>
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <UserHome />
                </PrivateRoute>
              </Layout>
            }
          />
          <Route
            exact
            path="/user/profile"
            element={
              <Layout>
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Profile />
                </PrivateRoute>
              </Layout>
            }
          />
          <Route
            exact
            path="/affidavit/:slug"
            element={
              <Layout>
                <Affidavit />
              </Layout>
            }
          />
          <Route
            exact
            path="/affidavit/cat/:slug"
            element={
              <Layout>
                <Category />
              </Layout>
            }
          />
          <Route
            exact
            path="/terms-and-conditions"
            element={
              <Layout>
                <TermsAndConditions />
              </Layout>
            }
          />
          <Route
            exact
            path="/privacy-policy"
            element={
              <Layout>
                <Privacy />
              </Layout>
            }
          />
          <Route
            exact
            path="/document"
            element={
              <Layout>
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Document />
                </PrivateRoute>
              </Layout>
            }
          />
          <Route
            exact
            path="/reference/:reference"
            element={
              <Layout>
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Success />
                </PrivateRoute>
              </Layout>
            }
          />
          <Route
            exact
            path="/support"
            element={
              <Layout>
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <SupportAndIssueForm />
                </PrivateRoute>
              </Layout>
            }
          />
          <Route
            element={
              <Layout>
                <NotFound />
              </Layout>
            }
          />
        </Routes>
      ) : (
        <div>
          <h2>Close the console to continue</h2>
        </div>
      )}
      {/* <PreventRightClick /> */}
      {/* <Routes>
        <Route
          exact
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          exact
          path="/account"
          element={
            <Layout showHeader={false} showFooter={false}>
              <AuthHome />
            </Layout>
          }
        />
        <Route
          exact
          path="/account/reset-password"
          element={
            <Layout showHeader={false} showFooter={false}>
              <PasswordReset />
            </Layout>
          }
        />
        <Route
          exact
          path="/user"
          element={
            <Layout>
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <UserHome />
              </PrivateRoute>
            </Layout>
          }
        />
        <Route
          exact
          path="/user/profile"
          element={
            <Layout>
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <Profile />
              </PrivateRoute>
            </Layout>
          }
        />
        <Route
          exact
          path="/affidavit/:slug"
          element={
            <Layout>
              <Affidavit />
            </Layout>
          }
        />
        <Route
          exact
          path="/affidavit/cat/:slug"
          element={
            <Layout>
              <Category />
            </Layout>
          }
        />
        <Route
          exact
          path="/terms-and-conditions"
          element={
            <Layout>
              <TermsAndConditions />
            </Layout>
          }
        />
        <Route
          exact
          path="/privacy-policy"
          element={
            <Layout>
              <Privacy />
            </Layout>
          }
        />
        <Route
          exact
          path="/document"
          element={
            <Layout>
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <Document />
              </PrivateRoute>
            </Layout>
          }
        />
        <Route
          exact
          path="/reference/:reference"
          element={
            <Layout>
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <Success />
              </PrivateRoute>
            </Layout>
          }
        />
        <Route
          exact
          path="/support"
          element={
            <Layout>
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <SupportAndIssueForm />
              </PrivateRoute>
            </Layout>
          }
        />
        <Route
          element={
            <Layout>
              <NotFound />
            </Layout>
          }
        />
      </Routes> */}
    </div>
  );
}

export default App;
