import { Footer } from "./Footer";
import { Navigation } from "./Navigation";
// import { SideBar } from "./SideBar";

export const Layout = ({ children, showHeader = true, showFooter = true }) => {
  return (
    <div>
      {showHeader && <Navigation />}
      {children}
      {showFooter && <Footer />}
    </div>
  );
};
