import LockPersonIcon from "@mui/icons-material/LockPerson";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useGlobalContext } from "../util/context";
import { SearchForm } from "./SearchForm";
import logo from "../images/aff-logo.png";

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { Link } from "react-router-dom";

export const Navigation = () => {
  const { isAuthenticated, toggleSidebar, allAffidavits } = useGlobalContext();

  const guestLinks = (
    <>
      <ul className="navbar-auth">
        <li className="navbar-auth__desktop">
          <Link to="/account/">
            <LockPersonIcon className="icon" />
            Login
          </Link>
        </li>
        <li className="navbar-auth__mobile" onClick={toggleSidebar}>
          <Link to="/account/">
            <LockPersonIcon className="menu-icon" />
            <span style={{ color: "#fff", fontSize: "1.5rem" }}>Login</span>
          </Link>
        </li>
      </ul>
    </>
  );
  const authLinks = (
    <>
      <ul className="navbar-auth">
        <li className="navbar-auth__desktop">
          <Link to="/user">
            <AccountBoxIcon className="icon" />
          </Link>
        </li>
        <li className="navbar-auth__mobile" onClick={toggleSidebar}>
          <Link to="/user">
            <AccountBoxIcon className="menu-icon" />
          </Link>
        </li>
      </ul>
    </>
  );
  return (
    <nav className="navbar" id="search">
      <div className="navbar-logo">
        <Link to="/">
          <img src={logo} alt="" className="logo" />
        </Link>
      </div>
      <div className="navbar-search">
        <SearchForm allAffidavits={allAffidavits} />
      </div>
      {isAuthenticated ? authLinks : guestLinks}
    </nav>
  );
};
