import React from "react";

export const TermsAndConditions = () => {
  return (
    <section style={{ fontSize: 16 }}>
      <h1 className="heading-tertiary">
        General Terms and Conditions of use of affidocs.com website and
        application
      </h1>
      <p>
        This is a legally binding agreement between you (either an individual or
        a single entity), referred to herein as “YOU”, and Technox solutions.
        Unless otherwise stated, "we" or "our" or "us" will refer collectively
        to Technox solutions and its subsidiaries, affiliates, directors,
        officers, employees, agents and partners.
      </p>
      <p>
        This web-based application entitled Affidocs and all documents and
        materials included in it are referred to herein as the “Website” or the
        “Application”. Signing up, logging in, or using all or any portion of
        this web-based application indicates your acceptance of all the terms
        and conditions of this End-User License Agreement referred to herein as
        “EULA” and that this agreement is equivalent to a written agreement
        signed by you. By using or purchasing any product or services on the
        website, you are providing your acceptance of the terms of this
        agreement. If you do not agree to the terms of this agreement, please do
        not use this application.
      </p>
      <p>
        The use of this web-based application is governed by the terms and
        conditions set forth herein. Please read them carefully.
      </p>
      <div>
        <h3> 1. Proprietary rights</h3>
        <p>
          Technox solutions and its partners retain all title, ownership, and
          intellectual property rights in the Application, including but not
          limited to all supporting documentation, files, marketing material,
          images, multimedia etc. The Application is protected by copyright and
          other intellectual property. The Application may include security
          measures designed to control access and prevent non-genuine users from
          using it. You agree not to interfere with any such security
          components. Technox solutions permits you to download document, use,
          or otherwise benefit from the functionality or intellectual property
          of the Application only in accordance with the terms of this EULA. The
          use of third party material included in the Website may be subject to
          the terms and conditions typically found in the party's own license
          agreement.
        </p>

        <h3>2. Confidentiality </h3>
        <p>
          Each party agrees that it shall not disclose to any third party any
          information concerning the customers, trade secrets, methods,
          processes or procedures or any other confidential, financial or
          business information of the other party which it learns during the
          course of its performance of this EULA, without the prior written
          consent of such other party. This obligation shall survive the
          cancellation or other termination of this EULA. The Application may
          contains trade secrets and proprietary know-how that belong to Technox
          solutions and it is being made available to you in strict confidence.
          ANY USE OR DISCLOSURE OF THE APPLICATION, OR OF ITS ALGORITHMS,
          PROTOCOLS OR INTERFACES, OTHER THAN IN STRICT ACCORDANCE WITH THIS
          EULA, MAY BE ACTIONABLE AS A VIOLATION OF TECHNOX SOLUTIONS TRADE
          SECRET RIGHTS.
        </p>

        <h3>3. Application updates </h3>
        <p>
          The Application is subject to periodic update and review with the view
          to taking out or adding features. By using the Application, you
          consent to receive notification from us highlighting what have been
          removed or added. Your use of the updates shall be governed by this
          EULA unless.
        </p>

        <h3> 4. Limitation of liability</h3>
        <p>
          EXCEPT AS REQUIRED BY LAW, TECHNOX SOLUTIONS AND ITS EMPLOYEES,
          DIRECTORS, LICENSORS, CONTRIBUTORS AND AGENTS WILL NOT BE LIABLE FOR
          ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY
          DAMAGES ARISING OUT OF OR IN ANY WAY RELATING TO THIS EULA OR THE USE
          OF OR INABILITY TO USE THE APPLICATION, INCLUDING WITHOUT LIMITATION
          DAMAGES FOR LOSS OF GOODWILL, WORK STOPPAGE, LOST PROFITS, LOSS OF
          DATA, AND COMPUTER FAILURE OR MALFUNCTION, EVEN IF ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF THE THEORY (CONTRACT,
          TORT OR OTHERWISE) UPON WHICH SUCH CLAIM IS BASED
        </p>

        <h3>5. Disclaimer of warranty. </h3>
        <p>
          YOU ACCEPT THE USE OF THE APPLICATION “AS IS” AND WITH ALL FAULTS (IF
          ANY). TECHNOX SOLUTIONS DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
          INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY AND FITNESS
          FOR A PARTICULAR PURPOSE. TECHNOX SOLUTIONS MAKES NO WARRANTY OR
          REPRESENTATIONS WHATSOEVER REGARDING THE CONTENT OF THE DOCUMENTS AND
          YOUR USE OF THE DOCUMENTS, OR THE SUITABILITY OF THE DOCUMENTS TO MEET
          YOUR SPECIFIC NEEDS. THE DOCUMENTS ARE DETAILED, BUT GENERIC IN FORM.
          Technox solutions makes no warranties with respect to the Documents as
          described above and as such, there should be no reason for you to
          bring any grievance against Technox solutions.
        </p>

        <h3>6. Termination </h3>
        <p>
          Technox Solutions shall have the sole and exclusive right to
          immediately terminate this EULA and deactivate your account to use the
          Application should you fail to perform any obligation required under
          this EULA, engage in activity that Technox Solutions deems harmful to
          its well-being.
        </p>

        <h3> 7. Waiver & severability</h3>
        <p>
          Technox Solutions’ waiver of any breach of this EULA shall not
          constitute an amendment to this EULA or Technox Solutions’ waiver of
          subsequent breaches. If any part of this EULA is found void and
          unenforceable, it will not affect the validity of the rest of this
          EULA, which will remain valid and enforceable according to its terms.
        </p>

        <h3>8. Electronic notices </h3>
        <p>
          You consent that Technox Solutions may provide you with information
          and notices regarding the Application and Technox Solutions via the
          email address you designate when signing up on the Website. Technox
          Solutions may provide notices to you via (i) email if you have
          provided Technox Solutions with a valid email address, or (ii) by
          posting the notice on the Website.
        </p>

        <h3>9. Amendments </h3>
        <p>
          Technox Solutions reserves the right to unilaterally amend all offers,
          pricing or other matters pertaining to the application at any time
          without notifying you.
        </p>

        <h3> 10. Questions or Comments</h3>
        <p>
          For inquiries please contact: Technox Solutions. email:
          cs@affidocs.com
        </p>
      </div>
    </section>
  );
};
