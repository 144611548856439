import { UserLinks } from "../../component/UserLinks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserHeaderText } from "../../component/UserHeaderText";
import ChangePassword from "../../component/ChangePassword";
import UpdateProfile from "../../component/UpdateProfile";
import { useGlobalContext } from "../../util/context";

function Profile() {
  const navigate = useNavigate();
  const { userToken } = useGlobalContext();
  const [queryParameters] = useSearchParams();
  const src = queryParameters.get("redirect-src");

  if (!userToken) {
    navigate(`/account?redirect-src=/user/profile`);
  }
  return (
    <div className="auth-home">
      <header className="user-header">
        <UserHeaderText text={"My Profile"} />
      </header>
      <section className="fl-row user-content">
        <UserLinks />
        <article className="fl-row wd-7 user-content__item ">
          <div className="profile">
            <UpdateProfile src={src} />
          </div>

          {/* <div className="profile">
            <ChangePassword />
          </div> */}
        </article>
      </section>
    </div>
  );
}
export default Profile;
