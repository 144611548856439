import React, { useState, useEffect } from "react";

export const Ordercard = ({
  name,
  cta,
  action,
  reference,
  source,
  date,
  // editAction,
}) => {
  const [formattedDate, setFormatted] = useState("");
  const formatDate = () => {
    const dateArray = date.split("-");
    setFormatted(`${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`);
  };
  useEffect(() => {
    formatDate();
  }, [date]);

  return (
    <div className="order">
      <div className="order__content">
        <h3>{name}</h3>
        <div>
          {source === "completed" || source === "pending" ? (
            <p
              style={{
                fontSize: 18,
              }}
            >
              Generated: {formattedDate}
            </p>
          ) : (
            <p
              style={{
                fontSize: 18,
              }}
            >
              Requested: {formattedDate}
            </p>
          )}
        </div>
        {source === "completed" || source === "pending" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button onClick={() => action(reference)} className="cta">
              {cta}
            </button>
            {/* <Button onClick={() => editAction()} variant="contained">
              Edit
            </Button> */}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <a href={`${action}`} className="cta">
              {cta}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
