import { RotatingSquare } from "react-loader-spinner";
import React from "react";

export const Processing = () => {
  return (
    <RotatingSquare
      height="100"
      width="100"
      color="#4fa94d"
      ariaLabel="rotating-square-loading"
      strokeWidth="4"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
    />
  );
};
