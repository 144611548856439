import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GoogleIcon from "@mui/icons-material/Google";
import { useGlobalContext } from "../util/context";
import { ColorRing } from "react-loader-spinner";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const SignUp = ({ toggle, from }) => {
  const navigate = useNavigate();

  const { handleEmailAndPasswordSignup, message, setMessage, isAuthenticated } =
    useGlobalContext();

  const [btnEmailClicked, setBtnEmailClicked] = useState(false);
  const [inputType, setInputType] = useState("password");

  const changeVisibility = () => {
    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };

  useEffect(() => {
    setMessage("");
  }, []);

  const [formData, setFormData] = useState({
    email: "",
    name: "",
    phone: "",
    password: "",
  });

  const { email, name, phone, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSignUp = async (e) => {
    e.preventDefault();
    setBtnEmailClicked(true);
    try {
      await handleEmailAndPasswordSignup(name, email, phone, password, from);
    } catch (err) {}
  };

  if (isAuthenticated) {
    navigate("/");
    // return null;
  }

  return (
    <section className="account-container">
      <article className="account">
        <div className="content">
          <div className="credential">
            <form className="form" onSubmit={(e) => handleSignUp(e)}>
              <h3>Create Free Account</h3>
              {message && (
                <div className="account-message">
                  <h3 className="message">
                    {message.includes(["auth/"]) ? (
                      <span className="error">
                        {message.slice(5)[0].toUpperCase() +
                          message.slice(6).replace(/-/g, " ")}
                      </span>
                    ) : (
                      <span className="error">{message}</span>
                    )}
                  </h3>
                </div>
              )}
              <div className="form__group">
                <input
                  className="form__input"
                  name="email"
                  required
                  value={email}
                  onChange={(e) => onChange(e)}
                  placeholder="Emaill address"
                />
              </div>
              <div className="form__group">
                <input
                  className="form__input"
                  name="name"
                  required
                  value={name}
                  onChange={(e) => onChange(e)}
                  placeholder="Enter full name"
                />
              </div>
              <div className="form__group">
                <input
                  className="form__input"
                  name="phone"
                  required
                  value={phone}
                  onChange={(e) => onChange(e)}
                  placeholder="Phone No."
                />
              </div>
              <div className="form__group input-pass">
                <input
                  className="form__input"
                  type={inputType}
                  name="password"
                  required
                  value={password}
                  onChange={(e) => onChange(e)}
                  placeholder="Create a password"
                />
                <button onClick={() => changeVisibility()} type="button">
                  {inputType === "password" ? (
                    <VisibilityOffIcon className="icon" />
                  ) : (
                    <VisibilityIcon className="icon" />
                  )}
                </button>
              </div>
              <div className="form__group btn-action">
                {btnEmailClicked && !message ? (
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <ColorRing />
                  </p>
                ) : (
                  <button className="cta">Create Account</button>
                )}
              </div>
            </form>
          </div>
          <div className="member">
            <span className="text">Already a member?</span>
            <button onClick={toggle} className="join">
              Login in
            </button>
          </div>
        </div>
      </article>
    </section>
  );
};

export default SignUp;
