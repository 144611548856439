import React, { useState, useEffect } from "react";
import { useGlobalContext } from "../util/context";
import { Link, useNavigate } from "react-router-dom";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GoogleIcon from "@mui/icons-material/Google";
import { ColorRing } from "react-loader-spinner";

const Login = ({ toggle, from }) => {
  const navigate = useNavigate();
  const {
    handleEmailAndPasswordLogin,
    // handleGoggleLogin,
    // handleFacebookLogin,
    message,
    setMessage,
    isAuthenticated,
  } = useGlobalContext();

  useEffect(() => {
    setMessage("");
  }, [setMessage]);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  // const [btnGoogleClicked, setBtnGoogleClicked] = useState(false);
  // const [btnFbClicked, setBtnFbClicked] = useState(false);
  const [btnEmailClicked, setBtnEmailClicked] = useState(false);

  // distructure formData
  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const emailAndPasswordLogin = async (e) => {
    e.preventDefault();
    setBtnEmailClicked(true);
    try {
      await handleEmailAndPasswordLogin(email, password, from);
    } catch (err) {}
  };

  // const loginWithGoogle = async () => {
  //   setBtnGoogleClicked(true);
  //   try {
  //     await handleGoggleLogin(from);
  //   } catch (err) {}
  // };

  // const loginWithFacebook = async () => {
  //   setBtnFbClicked(true);
  //   try {
  //     await handleFacebookLogin(from);
  //   } catch (err) {}
  // };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <section className="account-container">
        <article className="account">
          <div className="content">
            {/* <div className="social">
              <div className=" fl-row platforms">
                {btnGoogleClicked ? (
                  <ColorRing />
                ) : (
                  <button
                    type="button"
                    className="btn btn-google"
                    onClick={loginWithGoogle}
                  >
                    <span>
                      <GoogleIcon className="icon" sx={{ color: "green" }} />
                    </span>
                    <span className="btn-text">Login with Google</span>
                  </button>
                )}
                {btnFbClicked ? (
                  <ColorRing />
                ) : (
                  <button
                    type="button"
                    className="btn btn-facebook"
                    onClick={loginWithFacebook}
                  >
                    <span>
                      <FacebookIcon className="icon" sx={{ color: "blue" }} />
                    </span>
                    <span className="btn-text">Login with Facebook</span>
                  </button>
                )}
              </div>
            </div> */}
            {/* <div className="divider">
              <span>OR</span>
            </div> */}
            <div className="credential">
              <form className="form" onSubmit={(e) => emailAndPasswordLogin(e)}>
                <div className="form__group ">
                  {/* <h3>Login in with email & password</h3> */}
                  {message && (
                    <div className="account-message">
                      <h3 className="message">
                        {message.includes(["auth/"]) ? (
                          <>
                            <span className="error">
                              {message
                                .slice(5)
                                .replace(/-/g, " ")[0]
                                .toUpperCase() +
                                message.slice(6).replace(/-/g, " ")}
                            </span>
                          </>
                        ) : (
                          <span className="error">{message}</span>
                        )}
                      </h3>
                    </div>
                  )}
                  {!message && <h3>Login to Continue</h3>}

                  <input
                    className="form__input"
                    type="text"
                    name="email"
                    value={email}
                    onChange={(e) => onChange(e)}
                    placeholder="Emaill address"
                  />
                </div>
                <div className="form__group">
                  <input
                    className="form__input"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => onChange(e)}
                    placeholder="Password"
                  />
                </div>
                <div className="reset">
                  <Link to="/account/reset-password" className="pwd-reset">
                    Forgot your password?
                  </Link>
                </div>
                <div className="form__group btn-action">
                  {btnEmailClicked && !message ? (
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <ColorRing />
                    </p>
                  ) : (
                    <button className="cta">Login</button>
                  )}
                </div>
              </form>
            </div>
            <div className="member">
              <span className="text">Not a member yet?</span>
              <button onClick={toggle} className="join">
                Sign up
              </button>
            </div>
          </div>
        </article>
      </section>
    </>
  );
};

export default Login;
