import { useState, useEffect } from "react";
import { json, useNavigate, useSearchParams } from "react-router-dom";
import { useGlobalContext } from "../util/context";
// import CheckIcon from "@mui/icons-material/Check";
import { server } from "../config";

function UpdateProfile({ src }) {
  const { userToken, loggedInUser } = useGlobalContext();
  console.log(loggedInUser);
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const source = queryParameters.get("redirect-src");

  const [client_name, setClientName] = useState("");
  const [client_id, setClientID] = useState("");
  const [client_email, setClientEmail] = useState("");
  const [auth_provider, setClientAuth] = useState("");
  const [updateMessage] = useState("");

  useEffect(() => {
    setClientName(loggedInUser.client_name);
    setClientID(loggedInUser.client_id);
    setClientEmail(loggedInUser.client_email);
    setClientAuth(loggedInUser.auth_provider);
  }, [loggedInUser]);

  const [formData, setFormdata] = useState({
    client_phone: "",
  });
  const { client_phone } = formData;

  const onChange = (e) =>
    setFormdata({ ...formData, [e.target.name]: e.target.value });

  // Update client data
  const updateProfile = (e) => {
    e.preventDefault();
    const body = JSON.stringify({});

    fetch(`${server}/api/account/update-client-data/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          navigate(source);
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  };
  return (
    <>
      {/* {updateMessage && (
        <div className="message success">
          {updateMessage && updateMessage}
          <CheckIcon className="icon" />
        </div>
      )} */}

      {loggedInUser?.client_email &&
      loggedInUser?.client_name &&
      loggedInUser?.client_phone ? (
        <>
          <div className="profile-info">
            <h3>Profile Info</h3>
            <p>Email Address: {loggedInUser.client_email}</p>
            <p>Full Name: {loggedInUser.client_name}</p>
            <p>Phone Number: {loggedInUser.client_phone}</p>
          </div>
        </>
      ) : (
        <>
          <div className="profile-info">
            <h3>Update your profile</h3>
            <p>Phone Number: {loggedInUser?.client_phone}</p>
          </div>
          <form onSubmit={(e) => updateProfile(e)} className="update-form">
            <h4>
              You are almost there. Kindly update your account by entering your
              phone number for a better user experience.
            </h4>
            <div className="form__group">
              <input
                type="text"
                className="form__input"
                name="client_phone"
                // value={client_phone}
                onChange={(e) => onChange(e)}
                placeholder="Enter phone number"
              />
            </div>
            {!client_phone ? (
              <button
                type="submit"
                className="form__cta"
                disabled
                onClick={updateProfile}
              >
                save changes
              </button>
            ) : (
              <button type="submit" className="form__cta">
                save changes
              </button>
            )}
          </form>
        </>
      )}
    </>
  );
}
export default UpdateProfile;
