import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      {/* <Navigation /> */}
      <article className="not-found__content">
        <div className="not-found__content--left">
          <h1>Sorry, that page couldn't be found</h1>
          <div className="img">
            {/* <img src={writing} alt="" width={300} height={300} /> */}
            <p>
              Trying to generate a{" "}
              <Link to="/document">business or legal document</Link>? It's
              simple and easy. Tell us about it and we will get it done for you
              within an hour.
            </p>
          </div>
          <div>
            <Button>
              <a href="/document" className="cta">
                {" "}
                Chat with an in-house expert
              </a>
            </Button>
          </div>
        </div>
        <div className="not-found__content--right">
          <div className="doc-steps__content">
            <h3>Never mind, we got you covered</h3>
            <ul>
              <li>Click on Chat with an in-house expert above</li>
              <li>
                Provide a brief details of the document you need and submit
              </li>
              <li>
                One of the in-house experts will get in touch with you almost
                instantly
              </li>
              <li>
                The document will be generated and uploaded to your account
              </li>
              <li>
                Go to your <Link to="user/">account</Link> (you must be logged
                in to access this) to download the document
              </li>
            </ul>
          </div>
        </div>
      </article>
    </>
  );
};

export default NotFound;
