import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useGlobalContext } from "../util/context";
export const UserHeaderText = ({ text }) => {
  const { menuListOpen, toggleMenuList } = useGlobalContext();

  // On a big screen, the toggle "menuIcon" will not display because the menu list
  // it's meant to display are already displayed by default on large screen.
  // On a smaller screen, however, the button displays by default. The "menu-open"
  // class id defined in "style/pages/user.scss"
  return (
    <div className="header-container">
      <div className={`${menuListOpen ? "wd-2 btn menu-open" : "btn"}`}>
        <button onClick={toggleMenuList}>
          <MenuIcon className="icon" />
        </button>
      </div>
      <div className="wd-8 text">
        <h1>{text}</h1>
      </div>
    </div>
  );
};
