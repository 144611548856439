import { useState, useEffect } from "react";
import { useGlobalContext } from "../../util/context";
import { server } from "../../config";
import { useNavigate } from "react-router-dom";

export const SupportAndIssueForm = () => {
  const navigate = useNavigate();
  const { loggedInUser, userToken } = useGlobalContext();
  const [successOpen, setSuccessOpen] = useState(false);
  const [error, setError] = useState("");
  const [fieldValues, setFieldValues] = useState({
    subject: "",
    coment: "",
  });
  const [count, setCount] = useState(0);

  const { subject, coment } = fieldValues;

  const onChange = (e) =>
    setFieldValues({ ...fieldValues, [e.target.name]: e.target.value });

  const submit = async (e) => {
    e.preventDefault();
    const body = JSON.stringify({
      user: loggedInUser.id,
      subject,
      coment,
    });
    let csrftoken = null;
    const cookieString = document.cookie.trim();
    if (cookieString) {
      csrftoken = cookieString
        .split("; ")
        .find((cookie) => cookie.startsWith("csrftoken="))
        ?.split("=")[1];
    }

    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
      ...(csrftoken && { "X-CSRFToken": csrftoken }),
    });

    try {
      const url = `${server}/api/support/create/`;

      const response = await fetch(url, {
        method: "POST",
        headers,
        credentials: "include",
        body,
      });

      if (response.status === 201) {
        setSuccessOpen(true);
      }
    } catch (error) {
      setError(error?.message);
    }
  };

  useEffect(() => {
    if (successOpen) {
      const interval = setInterval(() => {
        setCount((prevCount) => prevCount + 1);
      }, 1000);

      if (count === 5) {
        clearInterval(interval);
        navigate("/user");
      }

      return () => clearInterval(interval);
    }
  }, [successOpen, count, navigate]);

  const circleStyle = {
    marginTop: "2rem",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    border: "5px solid lightgray",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
    position: "relative",
    background: `conic-gradient(lightgreen ${count * 60}deg, lightgray ${
      count * 60
    }deg)`,
  };

  if (successOpen) {
    return (
      <section className="support">
        <h3 className="cta">Ticket submitted successfully</h3>
        <div style={circleStyle}>{count}</div>
      </section>
    );
  }

  return (
    <section className="support auth-home">
      <h1 className="heading-secondary">How may we help you?</h1>
      <article className="form-container">
        <h3 className="error">{error && error}</h3>
        <form onSubmit={submit}>
          <div className="">
            <select name="subject" value={subject} onChange={onChange}>
              <option>Select a topic</option>
              <option>Account suspension</option>
              <option>Payment problem</option>
              <option>Login problem</option>
              <option>Other issue</option>
            </select>
          </div>
          <div>
            <textarea name="coment" value={coment} onChange={onChange} />
          </div>
          <div>
            <button className="cta">Submit</button>
          </div>
        </form>
      </article>
    </section>
  );
};

// import { useState } from "react";
// import { useGlobalContext } from "../../util/context";
// import { server } from "../../config";

// export const SupportAndIssueForm = () => {
//   const { loggedInUser, userToken } = useGlobalContext();
//   const [successOpen, setSuccessOpen] = useState(false);
//   const [error, setError] = useState("");
//   const [fieldValues, setFieldValues] = useState({
//     subject: "",
//     coment: "",
//   });

//   const { subject, coment } = fieldValues;

//   const onChange = (e) =>
//     setFieldValues({ ...fieldValues, [e.target.name]: e.target.value });

//   const submit = async (e) => {
//     e.preventDefault();
//     const body = JSON.stringify({
//       user: loggedInUser.id,
//       subject,
//       coment,
//     });
//     let csrftoken = null;
//     const cookieString = document.cookie.trim();
//     if (cookieString) {
//       csrftoken = cookieString
//         .split("; ")
//         .find((cookie) => cookie.startsWith("csrftoken="))
//         ?.split("=")[1];
//     }

//     const headers = new Headers({
//       Accept: "application/json",
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${userToken}`,
//       ...(csrftoken && { "X-CSRFToken": csrftoken }),
//       // "X-CSRFToken": csrftoken,
//     });

//     try {
//       const url = `${server}/api/support/create/`;

//       const response = await fetch(url, {
//         method: "POST",
//         headers,
//         credentials: "include",
//         body,
//       });

//       if (response.status === 201) {
//         setSuccessOpen(true);
//       }
//     } catch (error) {
//       setError(error?.message);
//     }
//   };

//   if (successOpen) {
//     return (
//       <section className="support">
//         <h3 className="cta">Ticket submitted successfully</h3>
//       </section>
//     );
//   }
//   return (
//     <section className="support auth-home">
//       <h1 className="heading-secondary">How may we help you?</h1>
//       <article className="form-container">
//         <h3 className="error">{error && error}</h3>
//         <form onSubmit={submit}>
//           <div className="">
//             <select name="subject" value={subject} onChange={onChange}>
//               <option>Select a topic</option>
//               <option>Account suspension</option>
//               <option>Payment problem</option>
//               <option>Login problem</option>
//               <option>Other issue</option>
//             </select>
//           </div>
//           <div>
//             <textarea name="coment" value={coment} onChange={onChange} />
//           </div>
//           <div>
//             <button className="cta">Submit</button>
//           </div>
//         </form>
//       </article>
//     </section>
//   );
// };
