import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../util/context";

export const SearchForm = () => {
  const { allAffidavitCategory } = useGlobalContext();
  const navigate = useNavigate();
  const [filteredList, setFilteredList] = useState([]); // Holds the list returned after a search match is found
  const [textEntered, setTextEntered] = useState(""); // Holds the search text entered by the user
  const [placeholder, setPlaceholder] = useState(
    "Search affidavit to generate..."
  );

  const handleChange = (event) => {
    const searchAffidavits = event.target.value;
    setTextEntered(searchAffidavits);
    const newFilterList = allAffidavitCategory.filter((value) => {
      return value.category
        .toLowerCase()
        .includes(searchAffidavits.toLowerCase());
    });

    if (searchAffidavits === "") {
      setFilteredList([]);
    } else {
      setFilteredList(newFilterList);
    }
  };

  const clearInput = () => {
    setFilteredList([]);
    setTextEntered("");
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (textEntered.length > 0) {
      navigate(`/affidavit/cat/${textEntered.replaceAll(" ", "-")}`);
      setFilteredList([]);
    }
  };

  const clearPlaceHolderText = () => {
    setPlaceholder("");
  };

  const restorePlaceholderText = () => {
    if (!placeholder) {
      setPlaceholder("Search affidavit to generate...");
    }
  };

  return (
    <div className="search-container">
      <form className="search-form" onSubmit={handleSearch}>
        <input
          placeholder={placeholder}
          value={textEntered}
          onChange={handleChange}
          onFocus={clearPlaceHolderText}
          onBlur={restorePlaceholderText}
        />
        {textEntered.length > 0 && (
          <span className="btn clear-btn">
            <CloseIcon fontSize="large" onClick={clearInput} />
          </span>
        )}
        <span className="btn search-btn">
          <SearchIcon fontSize="large" onClick={(e) => handleSearch(e)} />
        </span>
      </form>
      {filteredList.length !== 0 && (
        <div className="result">
          <div className="close">
            <button className="close__btn" onClick={clearInput}>
              X
            </button>
          </div>
          {filteredList?.map((item) => {
            return (
              <div key={item.category} className="item">
                <Link to={`/affidavit/cat/${item.slug}`} onClick={clearInput}>
                  {item.category}
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
