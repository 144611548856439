import ProjectIcon from "@mui/icons-material/AccountTree";
import { useGlobalContext } from "../util/context";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import LockResetIcon from "@mui/icons-material/LockReset";
import ReportIcon from "@mui/icons-material/Report";
import { Link } from "react-router-dom";

export const UserLinks = () => {
  const { handleLogout, menuListOpen, toggleMenuList } = useGlobalContext();

  return (
    <article
      className={`${
        menuListOpen ? "user-content__link menu-open" : "user-content__link"
      }`}
    >
      <div className="fl-col userLinks">
        <ul>
          <li onClick={toggleMenuList}>
            <Link to="/user/">
              <LocalLibraryIcon className="icon" /> Orders
            </Link>
          </li>
          <li onClick={toggleMenuList}>
            <Link to="/user/profile">
              <ProjectIcon className="icon" /> Profile
            </Link>
          </li>
          <li>
            <Link to="/support">
              <ReportIcon className="icon" /> Report an Issue
            </Link>
          </li>
          <li>
            <button onClick={() => handleLogout()}>
              <LockResetIcon className="icon" /> Logout
            </button>
          </li>
        </ul>
      </div>
    </article>
  );
};
