import React from "react";
import { Link } from "react-router-dom";

export const UserCanNotGenerateAffidavit = () => {
  return (
    <div className="generate-denied">
      <p className="message error">
        You have some orders yet to be completed. You have to complete at least
        one of them before you can generate another affidavit.
      </p>
      <div className="link">
        <Link to="/user" className="cta">
          View Pending Orders
        </Link>
      </div>
    </div>
  );
};
