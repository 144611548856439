import React from "react";
import { Link } from "react-router-dom";

export const AffidavitNotFound = ({ error, slug }) => {
  return (
    <section className="cat-notfound">
      {error.message && (
        <>
          <div className="step">
            <h3 className="heading-tertiary">
              {error.message.replaceAll("-", " ")}
            </h3>
            <p className="paragraph-primary">
              If you are sure that is a real affidavit you want to generate,
              let's get it ready for you within 30 munites
            </p>
          </div>
          <Link to={`/document?cat=${slug}&&src=cat`} className="cta">
            How it works
          </Link>
        </>
      )}
    </section>
  );
};
