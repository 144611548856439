import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import { useRef } from "react";
import { server } from "../config";
import { useGlobalContext } from "../util/context";
import { DisabledAccount } from "../component/DisabledAccount";
import CheckIcon from "@mui/icons-material/Check";

const Success = () => {
  const { loggedInUser, userToken } = useGlobalContext();
  const { reference } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [productID, setProductID] = useState(""); // product name in int(being a foreign key linking to the affidavit model)
  const [productName, setProductName] = useState(""); // product name in string
  const [title1, setTitle1] = useState("");
  const [title2, setTitle2] = useState("");
  const [title3, setTitle3] = useState("");
  const [courtCity, setCourtCity] = useState("");
  const [courtType, setCourtType] = useState("");
  const [body, setBody] = useState([]);
  const [hasReviewed, setHasReviewed] = useState(false);
  const [reviewMessage, setRevieMessage] = useState("");

  const [comentData, setComentData] = useState({
    coment: "",
  });
  const { coment } = comentData;
  const [documentError, setDocumenterror] = useState("");

  const onChange = (e) => {
    setComentData({ ...comentData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    const handleSelectStart = (e) => {
      e.preventDefault();
    };

    // Attach event listeners when the component mounts
    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("selectstart", handleSelectStart);

    // Remove event listeners when the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("selectstart", handleSelectStart);
    };
  }, []);

  useEffect(() => {
    const fetchAffidavit = async () => {
      const config = {
        method: "GET",
      };

      try {
        const response = await fetch(
          `${server}/api/order/completed/${reference}/`,
          config
        );

        if (!response.ok) {
          throw new Error("No document found.");
        }
        const affidavit = await response.json();
        if (affidavit.order_status === "Completed") {
          setProductID(affidavit?.product_name.id);
          setProductName(affidavit?.product_name.display_name);
          setTitle1(affidavit?.court_title_1);
          setTitle2(affidavit?.court_title_2);
          setTitle3(affidavit?.court_title_3);
          setBody(affidavit?.product_body.split(":"));
          setCourtCity(affidavit?.court_city);
          setCourtType(affidavit?.court_type);
          setLoading(false);
        }
      } catch (error) {
        setDocumenterror(error.message);
      }
    };
    fetchAffidavit();
  }, [reference, navigate]);
  //-------------------------------------------------
  useEffect(() => {
    const fetchReview = async () => {
      const config = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      try {
        const response = await fetch(
          `${server}/api/review/${productID}/${loggedInUser.id}/`,
          config
        );
        const data = await response.json();
        if (data) {
          setHasReviewed(true);
        }
      } catch (error) {}
    };
    fetchReview();
  }, [loggedInUser, hasReviewed, productID, userToken]);

  const [reviewOpen, setReviewOpen] = useState(false);
  const toggeleReview = () => {
    setReviewOpen(!reviewOpen);
  };

  const postReview = async (e) => {
    e.preventDefault();
    const body = JSON.stringify({
      author: loggedInUser.id,
      product: productID,
      coment,
    });
    let csrftoken = null;
    const cookieString = document.cookie.trim();
    if (cookieString) {
      csrftoken = cookieString
        .split("; ")
        .find((cookie) => cookie.startsWith("csrftoken="))
        ?.split("=")[1];
    }

    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
      ...(csrftoken && { "X-CSRFToken": csrftoken }),
      // "X-CSRFToken": csrftoken,
    });
    const url = `${server}/api/review/create/`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        credentials: "include",
        body,
      });

      if (response.status === 201) {
        toggeleReview();
        setHasReviewed(true);
        setRevieMessage("Your review saved successfully");
        window.scrollTo(0, 0);
        setTimeout(() => {
          setRevieMessage("");
        }, 4000);
      }
    } catch (error) {
      setRevieMessage("Sorry, something went wrong posting your review");
    }
  };

  //---------------------html to pdf converter----------------------------------------
  const reportTemplateRef = useRef(null);
  const downloadFile = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "pt",
    });

    // Adding the fonts.
    // doc.setFont("Inter-Regular", "normal");

    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        // await doc.save(productName);
        doc.save(productName);
      },
    });
  };

  if (loading) {
    return (
      <section style={{ minHeight: "100%" }}>
        <h2 style={{ textAlign: "center" }}>Please wait...</h2>
      </section>
    );
  }
  if (documentError) {
    return (
      <section style={{ minHeight: "100%" }}>
        <h2 style={{ textAlign: "center" }}>{documentError}</h2>
      </section>
    );
  }
  if (userToken && !loggedInUser.status) {
    return <DisabledAccount />;
  }

  return (
    <>
      <div className="aff-wraper">
        <h2>{documentError}</h2>
        <article>
          <ul className="post-download">
            <h2 className="heading-tertiary">What to do after download </h2>
            <div className="list-container">
              <span className="icon">
                <CheckIcon />
              </span>
              <li className="list">
                Print out three (3) copies of the affidavit
              </li>
            </div>
            <div className="list-container">
              <span className="icon">
                <CheckIcon />
              </span>
              <li className="list">
                Proceed to the court you choose with the three copies to sign
                them
              </li>
            </div>
            <h4>
              Note: Depending on the type of affidavit, you may need to go with
              your recent passport photograph to the court.
            </h4>
          </ul>
          {reviewMessage && <p className="flash">{reviewMessage}</p>}

          <div className="final" id="result" ref={reportTemplateRef}>
            <p className="final-head">{title1}</p>

            <p className="final-head">{title2}</p>

            <p className="final-head">{title3}</p>

            <h2 className="final-title">{productName}</h2>
            {body.map((segment, index) => {
              return (
                <div className="final-list">
                  {index !== 0 ? (
                    <span className="final-list__index">{index + "."}</span>
                  ) : (
                    ""
                  )}
                  <p
                    className={`paragraph-primary final-list__text ${
                      segment.trim().split(" ")[0].includes("(a)") ||
                      segment.trim().split(" ")[0].includes("(b)") ||
                      segment.trim().split(" ")[0].includes("(c)") ||
                      segment.trim().split(" ")[0].includes("(d)") ||
                      segment.trim().split(" ")[0].includes("(e)")
                        ? "leftPadded"
                        : ""
                    }`}
                    key={index}
                  >
                    {segment.replaceAll("undefined", "")}
                  </p>
                </div>
              );
            })}
            <div className="final-footer">
              <div className="deponent">
                <p>DEPONENT</p>
              </div>
              <div className="court-section">
                <p className="oath">Sworn to at the {courtType} registry,</p>
                <p className="city">
                  {courtCity}, this ________ day of _____________
                  {new Date().getFullYear()}.
                </p>
                <p className="court">BEFORE ME</p>
                <p className="court terminal">COMMISSIONER FOR OATHS</p>
              </div>
            </div>
          </div>
        </article>
      </div>
      <div className="download-aff">
        <button onClick={downloadFile} className="cta">
          Download
        </button>
        {!hasReviewed && (
          <Button
            variant="outlined"
            className={`${reviewOpen ? "close-review" : "review"}`}
            onClick={() => toggeleReview()}
          >
            {reviewOpen ? "Close review" : "Please write a review"}
          </Button>
        )}
      </div>
      {reviewOpen && (
        <div className="review">
          <h4>Please share your experience using affidocs.com.</h4>
          <p>
            Your review is important to us for improvement, and to prospective
            users trying affidocs.com for the first time.
          </p>
          <form onSubmit={postReview}>
            <div>
              <input
                type="textarea"
                placeholder="Write something"
                name="coment"
                value={coment}
                onChange={onChange}
                autoFocus
              />
            </div>
            <div>
              <button type="submit" className="cta">
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default Success;
