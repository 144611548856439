import { useState, useEffect } from "react";
import { useGlobalContext } from "../util/context";
import { useNavigate } from "react-router-dom";

export const AffidavitForm = ({
  question,
  fieldNames,
  fieldName,
  fieldValues,
  fieldValue,
  handleDayChange,
  handleMonthChange,
  handleYearChange,
  day,
  month,
  year,
  handleDay2Change,
  handleMonth2Change,
  handleYear2Change,
  day2,
  month2,
  year2,
  onChange,
  immediatePreviousFieldValue,
}) => {
  const { allCourts, allStates, isAuthenticated } = useGlobalContext();
  const navigate = useNavigate();
  // Preventing right-click and copying text
  // useEffect(() => {
  //   const handleContextMenu = (e) => {
  //     e.preventDefault();
  //   };

  //   const handleSelectStart = (e) => {
  //     e.preventDefault();
  //   };

  //   // Attach event listeners when the component mounts
  //   document.addEventListener("contextmenu", handleContextMenu);
  //   document.addEventListener("selectstart", handleSelectStart);

  //   // Remove event listeners when the component unmounts
  //   return () => {
  //     document.removeEventListener("contextmenu", handleContextMenu);
  //     document.removeEventListener("selectstart", handleSelectStart);
  //   };
  // }, []);

  const days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Extract all the courts from the chosen state
  const [allCourtsInAState, setAllCourtsInAState] = useState([]);
  useEffect(() => {
    const courtsInAState = allCourts.filter((court) => {
      return court?.state === immediatePreviousFieldValue;
    });
    setAllCourtsInAState(courtsInAState);
  }, [immediatePreviousFieldValue, allCourts]);

  //-----------------------------------------------------------
  // Extract all the loca governments from the chosen state
  const [localgvs, setLocalGovs] = useState([]);
  useEffect(() => {
    const state = allStates.find(
      (state) => state?.name === immediatePreviousFieldValue
    );
    setLocalGovs(state?.local_gov.split(","));
  }, [immediatePreviousFieldValue, allStates]);

  /* The question is made up of four parts: 
        (1). The question itself, 
        (2). The input type to collect the answer to the question,
        (3). The answer options in the case of select options.
        (4). The question tip for question that need better explanation
        (5). The gender pronouns for questions that requires the appropriate pronoun
        Each question is broken into the three parts and stored in the 
        "parts" array state below. The parts array is then splited, and 
        each item stored in "que", "inputType" and "options" respectively
    */
  const [que, setQues] = useState("");
  const [inputType, setInputType] = useState("");
  const [options, setOptions] = useState([]);
  const [queTip, setQueTip] = useState("");
  const [dependence, setDependence] = useState("");
  const [genderPronoun, setGenderPronoun] = useState([]);
  const [parts, setParts] = useState([]);

  useEffect(() => {
    setParts(question?.split(":"));
    setQues(parts[0]);
    setInputType(parts[1]);
    setOptions(parts[2]?.split(","));
    setQueTip(parts[3]);
    setDependence(parts[4]?.trim());
    setGenderPronoun(parts[5]?.split(","));
  }, [question, inputType]);

  if (!isAuthenticated) {
    navigate("/account");
  }
  return (
    <div className="form-container animated-form">
      {inputType && inputType.trim().toLowerCase() === "textfield" && (
        <div className="question">
          <label className="label">
            {que.includes("[") && que.includes("]")
              ? que
                  .trim()
                  .replace(
                    que
                      .substring(que.indexOf("["), que.indexOf("]") + 1)
                      .trim(),
                    fieldValues[
                      que
                        .substring(que.indexOf("[") + 1, que.indexOf("]"))
                        .trim()
                    ]
                  )
              : que.trim()}
          </label>

          {fieldName.slice(0, 4) !== "date" && (
            <div>
              {fieldName.slice(0, 3) === "age" ||
              fieldName.slice(0, 6) === "amount" ? (
                <input
                  name={fieldName}
                  value={fieldValue}
                  onChange={onChange}
                  type="number"
                />
              ) : (
                <input
                  name={fieldName}
                  value={fieldValue}
                  onChange={onChange}
                />
              )}
            </div>
          )}
          {fieldName.slice(0, 4) === "date" &&
            fieldName.slice(0, 5) !== "date2" && (
              <div className="date">
                <div className="date__input-hidden">
                  <input
                    name={fieldName}
                    value={fieldValue}
                    onChange={onChange}
                    hidden
                  />
                </div>

                <div className="date__input-select">
                  <select name="day" value={day} onChange={handleDayChange}>
                    <option> Day</option>
                    {days.map((day, index) => {
                      return (
                        <option value={day} key={index}>
                          {day}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    name="month"
                    value={month}
                    onChange={handleMonthChange}
                  >
                    <option>Month </option>
                    {months.map((month, index) => {
                      return (
                        <option value={month} key={index}>
                          {month}
                        </option>
                      );
                    })}
                  </select>
                  <select name="year" value={year} onChange={handleYearChange}>
                    <option> Year</option>
                    {Array.from(
                      { length: new Date().getFullYear() - 1959 },
                      (_, i) => i + 1960
                    ).map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

          {fieldName.slice(0, 5) === "date2" && (
            <div className="date">
              <div className="date__input-hidden">
                <input
                  name={fieldName}
                  value={fieldValue}
                  onChange={onChange}
                  hidden
                />
              </div>

              <div className="date__input-select">
                <select name="day2" value={day2} onChange={handleDay2Change}>
                  <option> Day</option>
                  {days.map((day, index) => {
                    return (
                      <option value={day} key={index}>
                        {day}
                      </option>
                    );
                  })}
                </select>
                <select
                  name="month2"
                  value={month2}
                  onChange={handleMonth2Change}
                >
                  <option>Month </option>
                  {months.map((month, index) => {
                    return (
                      <option value={month} key={index}>
                        {month}
                      </option>
                    );
                  })}
                </select>
                <select name="year2" value={year2} onChange={handleYear2Change}>
                  <option> Year</option>
                  {Array.from(
                    { length: new Date().getFullYear() - 1959 },
                    (_, i) => i + 1960
                  ).map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          {queTip.trim().toLowerCase() !== "none" && (
            <div className="tip">{queTip}</div>
          )}
          <br />
        </div>
      )}

      {inputType && inputType.trim().toLowerCase() === "select" && (
        <div className="question">
          <label className="label">
            {que.includes("[") && que.includes("]")
              ? que
                  .trim()
                  .replace(
                    que
                      .substring(que.indexOf("["), que.indexOf("]") + 1)
                      .trim(),
                    fieldValues[
                      que
                        .substring(que.indexOf("[") + 1, que.indexOf("]"))
                        .trim()
                    ]
                  )
              : que.trim()}
          </label>

          <br />
          <select name={fieldName} value={fieldValue} onChange={onChange}>
            <option> </option>
            {options[0].trim() === "states" && //state here is a tag in the database in the questions field
              dependence.trim() === "independent" &&
              allStates.map((state, index) => {
                return (
                  <option value={state.name} key={index}>
                    {state.name.trim()}
                  </option>
                );
              })}
            {options[0].trim() === "lgas" && // lgas here is a tag in the database in the questions field
              dependence.trim() === "linked" &&
              localgvs.map((lg, index) => {
                return (
                  <option value={lg} key={index}>
                    {lg.trim()}
                  </option>
                );
              })}
            {options[0].trim() === "courts" && // courts here is a tag in the database in the questions field
              dependence.trim() === "linked" &&
              allCourtsInAState.map((court) => {
                return (
                  <option value={court.name.trim()} key={court.id}>
                    {court.name.trim()}
                  </option>
                );
              })}

            {genderPronoun &&
              options.map((option, index) => {
                return (
                  <option value={genderPronoun[index]} key={index}>
                    {option.trim()}
                  </option>
                );
              })}

            {options &&
              options.length > 1 &&
              !genderPronoun &&
              options.map((option, index) => {
                return (
                  <option value={option.trim()} key={index}>
                    {option.trim()}
                  </option>
                );
              })}
          </select>
          <br />
          {queTip.trim().toLowerCase() !== "none" && (
            <div className="tip">{queTip}</div>
          )}
        </div>
      )}
    </div>
  );
};
