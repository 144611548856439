import { Link } from "react-router-dom";
import ChatIcon from "@mui/icons-material/Chat";
import { useState } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export const Footer = () => {
  const [chatOpen, setChatOpen] = useState(false);
  const tuggleChat = () => {
    setChatOpen(!chatOpen);
  };

  if (chatOpen) {
    return (
      <div className="chatBox">
        <div className="closeChat" onClick={tuggleChat}>
          X
        </div>
        <h3 style={{ textAlign: "center" }}>
          Got a question?{" "}
          <span style={{ display: "block", fontSize: "1.5rem" }}>
            Chat with us
          </span>
        </h3>
        <div className="whatsapp">
          <a
            href="https://wa.me/2347051129657"
            target="_blank"
            rel="noreferrer"
            style={{ textAlign: "center" }}
          >
            <WhatsAppIcon className="icon" onClick={tuggleChat} />
          </a>
        </div>
        <h3 style={{ textAlign: "center" }}>We are online</h3>
      </div>
    );
  }
  return (
    <footer className={`footer ${chatOpen ? "modal-overlay show-modal" : ""}`}>
      {/* <span className="design"> Powered by </span> */}
      <div className="col-row text">
        <p>© {new Date().getFullYear()} Affidoc. All right reserved</p>
        <Link
          to="/terms-and-conditions"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms
        </Link>
        <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">
          Policy
        </Link>
      </div>
      <button className="chat" onClick={tuggleChat}>
        <ChatIcon className="icon" />
      </button>
    </footer>
  );
};
