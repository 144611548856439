import React, { useState, useEffect } from "react";
import writer from "../images/writer.svg";
import InfoIcon from "@mui/icons-material/Info";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { server } from "../config";
import { Template } from "../component/Template";
import { SearchForm } from "../component/SearchForm";

const flow = [
  {
    id: 1,
    link: "info",
    icon: <InfoIcon className="icon" />,
    heading: "The magic begins with you",
    text: "Enter the affidavit you want to develop, and fill in your info correctly on each prompt to start the journey. ",
  },
  {
    id: 2,
    link: "process",
    icon: <SpellcheckIcon className="icon" />,
    heading: "Process document",
    text: "Let the system do the underground work and get your document ready for your preview and unlocking",
  },
  {
    id: 3,
    link: "unlock",
    icon: <LockOpenIcon className="icon" />,
    heading: "Proceed to checkout",
    text: "Easy and secure payment integration means you are just a step away from downloading the final document.",
  },
  {
    id: 4,
    link: "download",
    icon: <DownloadForOfflineIcon className="icon" />,
    heading: "Download",
    text: "Viola! Finally, you have your affidavit sitting right in front of you. Download and proceed to your chosen court for signing",
  },
];
function Home() {
  const shortText =
    " In the digital age, time and convenience are of the essence. Affidocs simplifies the process of generating ";

  const allText =
    "In the digital age, time and convenience are of the essence. Affidocs simplifies the process of generating affidavits in no time, conveniently and cost-effectively .";

  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  // ======= fetch users reviews
  const [reviews, setReviews] = useState([]);
  const [index, setIndex] = useState(0);
  const [showSearchForm, setShowSearchForm] = useState(false);

  const tugglePopUpSearch = () => {
    setShowSearchForm(!showSearchForm);
  };

  const fetchReviews = async () => {
    try {
      const response = await fetch(`${server}/api/review/active/`);
      const data = await response.json();
      if (data) {
        setReviews(data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchReviews();
  }, []);

  useEffect(() => {
    const lastIndex = reviews.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, reviews]);

  // Sliding of the process flow on button clip
  const [flowStep, setFlowStep] = useState(1);
  const toggleFlow = (index) => {
    setFlowStep(index);
  };

  const affSamples = [
    "Change of name",
    "Age declaration",
    "Good conduct",
    "Change of vehicle ownership",
    "Addition of name",
    "Correction of date of birth",
    "Comfirmation of name",
  ];

  //Automatic sliding of the process flow
  const [sampleAffidavits] = useState(affSamples);
  const [flowIndex, setFlowIndex] = useState(1);
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    const lastIndex = sampleAffidavits.length - 1;
    if (flowIndex < 0) {
      setFlowIndex(lastIndex);
    }
    if (flowIndex > lastIndex) {
      setFlowIndex(0);
    }
  }, [flowIndex, sampleAffidavits]);

  useEffect(() => {
    let slider = setInterval(() => {
      setFlowIndex(flowIndex + 1);
      setAnimation(true);
    }, 7000);
    return () => {
      clearInterval(slider);
    };
  }, [flowIndex]);

  return (
    <div className="hm-container">
      {showSearchForm ? (
        <div className={`${showSearchForm ? "modal-overlay show-modal" : ""}`}>
          <div className="search-popup">
            <span className="close-search">
              <button onClick={tugglePopUpSearch}>X</button>
            </span>
            <SearchForm />
          </div>
        </div>
      ) : (
        <>
          <div className="fl-col hm-header">
            <section className=" fl-row hm-hero">
              <article className="wd-5 hm-hero__text">
                <h1 className="heading-primary">
                  Need an affidavit? Generate it in a few button clicks
                </h1>
                <p className="paragraph-primary">
                  {showAll ? allText : shortText}{" "}
                  {showAll ? (
                    <span onClick={() => toggleShowAll()}>show less</span>
                  ) : (
                    <span onClick={() => toggleShowAll()}>show more</span>
                  )}
                </p>
                <div className="hero-cta">
                  {" "}
                  <button
                    href="#search"
                    className="cta"
                    onClick={tugglePopUpSearch}
                  >
                    Generate Affidavit
                  </button>
                </div>
              </article>
              <article className="wd-5 hm-hero__video">
                <div className="cle">
                  <Template
                    displayName={affSamples[flowIndex]}
                    animation={animation}
                  />
                </div>
              </article>
            </section>
          </div>

          <section className="app-flow">
            <article className="app-flow__inner">
              <div>
                <h2 className="heading-secondary">
                  <span className="outer">
                    <span>Simplicity</span>
                    <span>Ease of use</span>
                    <span>Cost effectiveness</span>
                  </span>
                  at a confluence
                </h2>
              </div>
              <div className="slide">
                <div className="slide__steps">
                  {flow.map((fl) => {
                    return (
                      <span
                        key={fl.id}
                        onClick={() => toggleFlow(fl.id)}
                        className={`${
                          flowStep === fl.id ? "active-link" : "inactive"
                        }`}
                      >
                        {fl.icon}
                        {fl.link}
                      </span>
                    );
                  })}
                </div>
                <div className="slide__content">
                  {/* <div> */}
                  {flow.map((fl) => {
                    return (
                      <div
                        className={`${flowStep === fl.id ? "active" : "item"}`}
                        key={fl.id}
                      >
                        <h3>{fl.heading}</h3>
                        <ul>
                          <li>{fl.text}</li>
                        </ul>
                      </div>
                    );
                  })}
                  <div className="get-started">
                    <button
                      href="#search"
                      className="cta"
                      onClick={tugglePopUpSearch}
                    >
                      Generate affidavit
                    </button>
                  </div>
                </div>
              </div>
            </article>
          </section>

          <section className="fl-row home-doc">
            <article className="wd-5 home-doc__text">
              <h3 className="heading-secondary">
                The <span>in-house experts</span> are waiting on the side line
              </h3>
              <p className="paragraph-primary">
                The system couldn't understand the affidavit you are trying to
                create? Never mind! With the Affidocs in-house experts, it's a
                matter of minutes before it's written and uploaded to your
                account.
              </p>
              <button
                href="#search"
                className="cta"
                onClick={tugglePopUpSearch}
              >
                Get started
              </button>
            </article>
            <article className="wd-5 home-doc__img">
              <img src={writer} alt="" />
            </article>
          </section>

          <section className="app-feature">
            <article className="circle-article">
              <div className="circle-image"></div>
            </article>
            <article className="app-feature__text">
              <h3 className="heading-secondary">
                Generate it once, Own it for good
              </h3>
              <p className="paragraph-primary">
                Whatever document you generate or created for you on Affidocs
                stays in your account for life. You can download it anytime at
                no extra cost.
              </p>
            </article>
          </section>

          {reviews.length > 0 && (
            <section className="review">
              <div className="review-heading">
                <h3 className="heading-secondary">The users are loving it</h3>
                <p className="paragraph-primary">
                  It's not often that a single site combines intuitiveness,
                  robust customer support, ease of use and cost effectiveness.
                  Affidocs combines all, and the users are loving it. You too
                  will!
                </p>
              </div>
              <div className="review-text">
                {reviews?.map((review, reviewIndex) => {
                  const { id, author, coment } = review;
                  let position = "nextSlide";
                  if (reviewIndex === index) {
                    position = "activeSlide";
                  }
                  if (
                    reviewIndex === index - 1 ||
                    (index === 0 && reviewIndex === reviews.length - 1)
                  ) {
                    position = "lastSlide";
                  }
                  return (
                    <div className={`review ${position}`} key={id}>
                      <h4>{author.client_name}</h4>
                      <p className="coment">{coment}</p>
                      <FormatQuoteIcon className="icon" />
                    </div>
                  );
                })}
                <button className="prev" onClick={() => setIndex(index - 1)}>
                  <KeyboardArrowLeftIcon />
                </button>
                <button className="next" onClick={() => setIndex(index + 1)}>
                  <KeyboardArrowRightIcon />
                </button>
              </div>
            </section>
          )}
        </>
      )}
    </div>
  );
}
export default Home;
