import Close from "@mui/icons-material/Close";
import { useGlobalContext } from "../util/context";
import { useNavigate } from "react-router-dom";
import { server } from "../config";
import { useEffect, useState } from "react";
import { Processing } from "./Processing";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

export const AffidavitResult = ({
  client,
  product_name,
  displayName,
  court_title_1,
  court_title_2,
  court_title_3,
  court_city,
  court_type,
  formattedBody,
  product_body,
  amount_paid,
  payment_reference,
  isResultModalOpen,
  setIsResultModalOpen,
  userPendingOrder,
  openCheckout,
}) => {
  const { userToken } = useGlobalContext();
  const navigate = useNavigate();

  const [showProcessing, setShowProcessing] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShowProcessing(false);
    }, 2000);
  });

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    const handleSelectStart = (e) => {
      e.preventDefault();
    };

    // Attach event listeners when the component mounts
    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("selectstart", handleSelectStart);

    // Remove event listeners when the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("selectstart", handleSelectStart);
    };
  }, []);

  const postOrder = async () => {
    let postStatus = false;
    const order_status = "Pending";
    const body = JSON.stringify({
      client,
      product_name,
      court_title_1,
      court_title_2,
      court_title_3,
      court_city,
      court_type,
      product_body,
      order_status,
      payment_reference,
      amount_paid,
    });

    let csrftoken = null;
    const cookieString = document.cookie.trim();
    if (cookieString) {
      csrftoken = cookieString
        .split("; ")
        .find((cookie) => cookie.startsWith("csrftoken="))
        ?.split("=")[1];
    }

    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
      ...(csrftoken && { "X-CSRFToken": csrftoken }),
      // "X-CSRFToken": csrftoken,
    });
    const config = {
      method: "POST",
      headers: headers,
      credentials: "include",
      body: body,
    };
    try {
      const response = await fetch(`${server}/api/order/create/`, config);

      if (response.status === 201) {
        postStatus = true;
      }
    } catch (error) {
      postStatus = false;
    }

    return postStatus;
  };

  const closeResultModal = () => {
    if (postOrder()) {
      setIsResultModalOpen(!isResultModalOpen);
      navigate("/user");
    }
  };

  return (
    <article className="modal-overlay show-modal">
      {!userPendingOrder ? (
        <>
          {isResultModalOpen && !showProcessing ? (
            <>
              <div className="close">
                <Close className="icon" onClick={closeResultModal} />
              </div>
              <div className="result" id="result">
                <h1 className="result-head">
                  {court_title_1}
                  <br />
                  {court_title_2}
                  <br />
                  {court_title_3}
                </h1>
                <h2 className="result-title">{displayName}</h2>
                {formattedBody?.map((body, index) => {
                  return (
                    <div className="result-list">
                      {index !== 0 ? (
                        <span className="result-list__index">
                          {index + "."}
                        </span>
                      ) : (
                        ""
                      )}
                      <p
                        className={`paragraph-primary result-list__text ${
                          body.trim().split(" ")[0].includes("(a)") ||
                          body.trim().split(" ")[0].includes("(b)") ||
                          body.trim().split(" ")[0].includes("(c)") ||
                          body.trim().split(" ")[0].includes("(d)") ||
                          body.trim().split(" ")[0].includes("(e)")
                            ? "leftPadded"
                            : ""
                        }`}
                        key={index}
                      >
                        {body}
                      </p>
                    </div>
                  );
                })}
                <div className="result-footer">
                  <div className="deponent">
                    <p>DEPONENT</p>
                  </div>
                  <div className="court-section">
                    <p className="oath">
                      Sworn to at the {court_type} registry,
                    </p>
                    <p className="city">
                      {court_city}, THIS ________ DAY OF _____________
                      {new Date().getFullYear()}.
                    </p>
                    <p className="court">BEFORE ME</p>
                    <p className="court terminal">COMMISSIONER FOR OATHS</p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="result search">
              <Processing />
            </div>
          )}
        </>
      ) : (
        <div className="unlock position">
          <div
            style={{
              textAlign: "right",
              cursor: "pointer",
              fontSize: 20,
            }}
          >
            <Close
              className="icon"
              onClick={() => setIsResultModalOpen(!isResultModalOpen)}
            />
          </div>
          <h3
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              fontSize: 20,
            }}
          >
            <span>
              <PictureAsPdfIcon style={{ fontSize: 20, marginRight: 10 }} />
            </span>
            {displayName}
          </h3>
          <button onClick={openCheckout} className="cta">
            Download & Print
          </button>
        </div>
      )}
    </article>
  );
};
