import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
// import { server } from "../config";
// import { useGlobalContext } from "../util/context";
// import { DisabledAccount } from "../component/DisabledAccount";

const Document = () => {
  // const { loggedInUser, userToken } = useGlobalContext();
  // const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const cat = queryParameters.get("cat"); // coming from Affidavit.js around line 568
  const src = queryParameters.get("src"); // coming from Affidavit.js around line 568
  // const { slug } = useParams();

  const [chatOpen, setChatOpen] = useState(false);
  // const [message, setMessage] = useState("");

  // const [fieldValues, setFieldValues] = useState({
  //   document: `I need ${cat.replace(/-/g, " ")}`,
  //   schedule: "",
  // });

  // const { document, schedule } = fieldValues;

  // const onChange = (e) =>
  //   setFieldValues({ ...fieldValues, [e.target.name]: e.target.value });

  // const submitRequest = async (e) => {
  //   e.preventDefault();
  //   if (document.cookie) {
  //     //connect to the django backend
  //     const csrftoken = document.cookie
  //       .split("; ")
  //       .find((cookie) => cookie.startsWith("csrftoken="))
  //       .split("=")[1];

  //     const headers = new Headers({
  //       "Content-Type": "application/json",
  //       "X-CSRFToken": csrftoken,
  //       Authorization: `Bearer ${userToken}`,
  //     });

  //     const config = {
  //       method: "POST", // or 'PUT', 'PATCH', 'DELETE', etc.
  //       headers: headers,
  //       credentials: "include", // Include credentials (cookies) in the request
  //       body: JSON.stringify({
  //         user: loggedInUser.id,
  //         document,
  //         schedule,
  //       }),
  //     };
  //     const url = `${`${server}/api/request/post-request/`}`;
  //     try {
  //       const response = await fetch(url, {
  //         config,
  //       });
  //       if (response.status === 201) {
  //         setChatOpen(true);
  //       }
  //     } catch (error) {
  //       setMessage(error);
  //     }
  //   } else {
  //     setMessage("Unable to submit your data. Try again later");
  //   }
  // };

  const toggleChart = () => {
    setChatOpen(!chatOpen);
  };
  return (
    <>
      {chatOpen ? (
        <section className="document chat">
          <article className="document__content">
            <div style={{ marginBottom: 20, textAlign: "right" }}>
              <button
                onClick={toggleChart}
                style={{
                  fontSize: 25,
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                X
              </button>
            </div>
            <div>
              {/* <h1>Request submitted successfully</h1> */}
              <h1>Chat with an in-house expert now</h1>
              <a href="https://wa.me/2347051129657">
                <WhatsAppIcon className="whatsapp-icon" />
              </a>
            </div>
          </article>
        </section>
      ) : (
        <section className="document">
          <article className="wd-5 document__text">
            <div className="document__text--header">
              {cat && src === "cat" ? (
                <h1 className="heading-tertiary">
                  Let's get your{" "}
                  <span style={{ color: "orange" }}>
                    {cat.replaceAll("-", " ")}
                  </span>{" "}
                  ready within <span>30 munites</span> for just{" "}
                  <span>N1,500</span>
                </h1>
              ) : (
                <h1 className="heading-tertiary">
                  Let's get your affidavit of{" "}
                  <span style={{ color: "orange" }}>
                    {cat.replaceAll("-", " ")}
                  </span>{" "}
                  ready within <span>1 hour</span> for just <span>N1,500</span>
                </h1>
              )}
            </div>
            <div className="document__text--steps">
              <h2 className="heading-tertiary">How it works</h2>
              <ul>
                <li>
                  <CheckCircleOutlineIcon className="icon" />{" "}
                  <span>
                    Click the Start Chart button to connect with an in-house
                    person
                  </span>
                </li>
                <li>
                  <CheckCircleOutlineIcon className="icon" />{" "}
                  <span>
                    Provide the information needed to create the document.
                  </span>
                </li>
                <li>
                  <CheckCircleOutlineIcon className="icon" />{" "}
                  <span>Proceed to pay.</span>
                </li>
                <li>
                  <CheckCircleOutlineIcon className="icon" />{" "}
                  <span>Receive and download the document</span>
                </li>
                <li>
                  <CheckCircleOutlineIcon className="icon" />{" "}
                  <span>
                    Print it out and go to the chosen court for signing.
                  </span>
                </li>
                <h3>It's as simple as that!</h3>
              </ul>
            </div>
          </article>
          <article className="wd-5 document__content">
            <WhatsAppIcon className="icon" />
            <div>
              <button type="submit" className="cta" onClick={toggleChart}>
                Start Chart
              </button>
            </div>
          </article>
        </section>
      )}
      ;
    </>
  );
};

export default Document;
