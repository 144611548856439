import { UserLinks } from "../../component/UserLinks";
import { UserHeaderText } from "../../component/UserHeaderText";
import { Ordercard } from "../../component/Ordercard";
import Checkout from "../../component/Checkout";
import { server } from "../../config";
import { useNavigate } from "react-router-dom";
// import { EditAffidavit } from "../../component/EditAffidavit";
import { useGlobalContext } from "../../util/context";
import { Triangle } from "react-loader-spinner";
import { useEffect, useState } from "react";

function UserHome() {
  const { userToken, loggedInUser } = useGlobalContext();
  const [orderTopayFor, setOrderToPayFor] = useState({});
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
  const [clientOrders, setClientOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const [requestedOrder, setRequestedOrder] = useState();
  const navigate = useNavigate();

  // Fetch all user requested orders
  const fetchRequestedOrders = async () => {
    const config = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    try {
      const response = await fetch(
        `${server}/api/request/client-requests/`,
        config
      );
      const data = await response.json();
      if (data) {
        setRequestedOrder(data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchRequestedOrders();
  }, [userToken]);

  // Generate payment reference
  const [paymentReference, setPaymentReference] = useState();
  useEffect(() => {
    const generateref = async () => {
      try {
        const response = await fetch(`${server}/api/order/ref/`);
        const reference = await response.json();
        if (response.status === 200) {
          setPaymentReference(reference.ref);
        }
      } catch (error) {}
    };
    generateref();
  }, []);
  // Fetch all others by a user using his order id
  const fetchOrders = async () => {
    const config = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    try {
      const response = await fetch(
        `${server}/api/order/client-orders/`,
        config
      );
      const data = await response.json();
      if (data) {
        setClientOrders(data);
        const complete = data.filter(
          (order) => order.order_status.toLowerCase() === "completed"
        );
        setCompletedOrders(complete);
        const pending = data.filter(
          (order) => order.order_status.toLowerCase() === "pending"
        );
        setPendingOrders(pending);
        setLoading(false);
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchOrders();
  }, [userToken]);

  const proceedToDownload = (reference) => {
    navigate(`/reference/${reference}`);
  };

  const proceedToCheckout = (reference) => {
    const order = clientOrders.find(
      (order) => order.payment_reference === reference
    );
    setOrderToPayFor(order);
    setIsCheckoutOpen(true);
  };

  const editaffidavit = () => {
    setOpenEdit(true);
  };

  const {
    // client,
    product_name,
    product_title_1,
    product_title_2,
    product_title_3,
    product_body,
    payment_reference,
    amount_paid,
  } = orderTopayFor;

  if (isCheckoutOpen) {
    return (
      <Checkout
        client={loggedInUser.id} //The client id
        clientName={loggedInUser.client_name} // The client name
        phone={loggedInUser.client_phone}
        email={loggedInUser.client_email}
        displayName={product_name.display_name} // product name in text
        product_name={product_name} // product name in id(being a related field)
        product_title_1={product_title_1}
        product_title_2={product_title_2}
        product_title_3={product_title_3}
        product_body={product_body}
        payment_reference={payment_reference}
        amount_paid={amount_paid}
        source="order"
        isCheckoutOpen={isCheckoutOpen}
        setIsCheckoutOpen={setIsCheckoutOpen}
      />
    );
  }

  if (loading) {
    return (
      <section
        style={{
          justifyContent: "center",
          alignItems: "center",
          fontSize: 25,
          height: "70vh",
        }}
        className="fl-row"
      >
        <Triangle
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="triangle-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </section>
    );
  }

  if (!userToken) {
    navigate(`/account?redirect-src=/user`);
  }
  return (
    <div className="auth-home">
      <header className="user-header">
        <UserHeaderText text="My orders" />
      </header>
      <section className="fl-row user-content">
        <UserLinks />
        <article className="fl-col user-content__item">
          <div>
            <h2>Completed order(s)</h2>
            <div className="fl-row order-container">
              {completedOrders.length > 0 ? (
                completedOrders.map((order) => {
                  return (
                    <div key={order.id}>
                      <Ordercard
                        name={order.product_name.display_name}
                        cta="Download"
                        action={proceedToDownload}
                        reference={order.payment_reference}
                        source="completed"
                        date={order.date}
                        editAction={editaffidavit}
                      />
                    </div>
                  );
                })
              ) : (
                <h4>No completed orders </h4>
              )}
            </div>
          </div>
          <div>
            <h2>Pending order(s)</h2>
            {pendingOrders.length > 0 && (
              <p
                style={{
                  border: "2px solid orange",
                  padding: 5,
                  fontSize: 15,
                  marginBottom: 8,
                  // background: "orange",
                }}
              >
                {pendingOrders.length > 0
                  ? "NOTE: You can only have maximum of two pending orders. After that, you wont be able to generate another affidavit untill you have checkout on at least one of them."
                  : ""}
              </p>
            )}

            <div className="fl-row order-container">
              {pendingOrders.length > 0 ? (
                pendingOrders.map((order) => {
                  return (
                    <div key={order.id}>
                      <Ordercard
                        name={order.product_name.display_name}
                        cta="Checkout"
                        action={proceedToCheckout}
                        reference={order.payment_reference}
                        date={order.date}
                        source="pending"
                        editAction={editaffidavit}
                      />
                    </div>
                  );
                })
              ) : (
                <h4>No pending orders</h4>
              )}
            </div>
          </div>
          <div>
            <h2>Requested order(s)</h2>
            <div className="fl-row order-container">
              {requestedOrder && requestedOrder.length > 0 ? (
                requestedOrder.map((order) => {
                  return (
                    <div key={order.id}>
                      <Ordercard
                        name={order.title}
                        cta="Download"
                        action={`documents/${order.title}.pdf`}
                        reference={paymentReference}
                        source="requested"
                        date={order.date}
                      />
                    </div>
                  );
                })
              ) : (
                <h4>No requested orders</h4>
              )}
            </div>
          </div>
        </article>
      </section>
    </div>
  );
}
export default UserHome;
