import { Triangle } from "react-loader-spinner";
export const Loader = () => {
  return (
    <section
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 25,
        height: "70vh",
      }}
      className="fl-row"
    >
      <Triangle
        height="80"
        width="80"
        color="#4fa94d"
        ariaLabel="triangle-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
      <p style={{ fontSize: 15, paddingTop: 10 }}>Processing...Please wait</p>
    </section>
  );
};
