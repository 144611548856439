import { useState, useEffect, createContext, useContext } from "react";
import { server } from "../config";
import { fireBaseApp } from "../firebase/firebaseApp";
import {
  getAuth,
  // signInWithPopup,
  // GoogleAuthProvider,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updatePassword,
  signOut,
  // FacebookAuthProvider,
} from "firebase/auth";
import { Loader } from "../component/Loader";
import { useNavigate } from "react-router-dom";
/*----------------Create the context --------------*/
const AppContext = createContext();
// export const useAuth = () => useContext(AppContext);
const AppProvider = ({ children }) => {
  const navigate = useNavigate();
  const auth = getAuth(fireBaseApp);

  const [user, setUser] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  /*----------------Navigation functionality --------*/
  const [showSidebar, setShowSidebar] = useState(false);

  const closeSidebar = () => {
    setShowSidebar(false);
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const sendUserInfoToDatabase = async (
    client_id,
    client_name,
    client_email,
    client_phone = "",
    auth_provider,
    sent_to
  ) => {
    const redirect = sent_to;
    const body = JSON.stringify({
      client_id,
      client_name,
      client_email,
      client_phone,
      auth_provider,
    });

    let csrftoken = null;
    const cookieString = document.cookie.trim();
    if (cookieString) {
      csrftoken = cookieString
        .split("; ")
        .find((cookie) => cookie.startsWith("csrftoken="))
        ?.split("=")[1];
    }

    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(csrftoken && { "X-CSRFToken": csrftoken }),
    });

    const config = {
      method: "POST",
      headers: headers,
      credentials: "include",
      body: body,
    };

    try {
      const response = await fetch(`${server}/api/account/add-client/`, config);

      if (!response.ok) {
        throw new Error(`Something went wrong creating new client`);
      }

      setIsAuthenticated(true);
      if (auth_provider === "email&password") {
        navigate(redirect);
      } else {
        navigate(`/user/profile?redirect-src=${redirect}`);
      }
    } catch (error) {
      // Improved error handling
      if (error.message.includes("Client with this email already exists")) {
        navigate("/");
        setIsAuthenticated(true);
      } else {
        setMessage("An error occurred. Please try again.");
      }
    }
  };

  // Fetch the logged in user
  const [loggedInUser, setLoggedInUser] = useState({});
  const loaduser = async (token) => {
    try {
      const config = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(
        `${server}/api/account/fetch-client-data/`,
        config
      );
      const data = await response.json();
      if (response.status === 200) {
        setLoggedInUser(data);
      }
    } catch (error) {}
  };

  // Handle the user auth state. It's built in to the google firebase authentication package
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser({
          user,
        });
        setUserToken(await user.getIdToken()); // Need this token in some pages to send request to backend
        loaduser(await user.getIdToken()); // This enables us fetch the user data from the backend
        setIsAuthenticated(true);
      } else {
        setUser(null);
        setIsAuthenticated(false);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, [auth]);

  const handleEmailAndPasswordSignup = (name, email, phone, password, from) => {
    setMessage("");
    if (name && email && phone && password) {
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          // User signed up successfully
          await sendUserInfoToDatabase(
            userCredential.user.uid,
            name,
            userCredential.user.email,
            phone,
            "email&password",
            from
          );
          setUser(userCredential.user);
        })
        .catch((error) => {
          setMessage(error.code, error.message);
          // return;
        });
    } else {
      setMessage("Enter email and password to create a new account");
    }
  };

  const handleEmailAndPasswordLogin = (email, password, from) => {
    setMessage("");
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // User logged in successfully
        navigate(from);
        setIsAuthenticated(true);
        setUser(userCredential.user);
      })
      .catch((error) => {
        setMessage(error.code, error.message);
      });
  };

  const handleForgotPassword = (email) => {
    setMessage("");
    sendPasswordResetEmail(auth, email, {
      url: "http://localhost:3000/account/",
    })
      .then(() => {
        setMessage(
          `A password reset link has been sent to ${email}. Click on it to change your password `
        );
      })
      .catch((error) => {
        setMessage(error.code, error.message);
        console.error(error.code, error.message);
      });
  };
  const handleUpdatePassword = (password) => {
    setMessage("");
    updatePassword(auth, password)
      .then(() => {
        setMessage(`Password Updated successfully`);
      })
      .catch((error) => {
        setMessage(error.code, error.message);
        console.error(error.code, error.message);
      });
  };
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate("/account");
        setIsAuthenticated(false);
        setUser(null);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /*----------------Functionality for fetching all courts from the database----*/
  const [allCourts, setAllCourts] = useState([]);
  useEffect(() => {
    const fetchCourts = async () => {
      const courts = await fetch(`${server}/api/court/list/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });
      const data = await courts.json();
      if (data) {
        setAllCourts(data);
      }
    };
    fetchCourts();
  }, []);

  /*----------------Functionality for fetching all states from the database----*/
  const [allStates, setAllStates] = useState([]);
  useEffect(() => {
    const fetchStates = async () => {
      const states = await fetch(`${server}/api/states/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });
      const data = await states.json();
      if (data) {
        setAllStates(data);
      }
    };
    fetchStates();
  }, []);

  /*----------------Functionality for fetching all affidavits from the database----*/
  const [allAffidavits, setAllAffidavits] = useState([]);
  useEffect(() => {
    const fetchAffidavits = async () => {
      const affidavits = await fetch(`${server}/api/affidavit/affidavits/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });
      const data = await affidavits.json();
      if (data) {
        setAllAffidavits(data);
      }
    };
    fetchAffidavits();
  }, []);

  /*----------------Functionality for fetching all categories from the database----*/
  const [allAffidavitCategory, setAllAffidavitCategory] = useState([]);
  useEffect(() => {
    const fetchAffidavitCategories = async () => {
      const categories = await fetch(`${server}/api/affidavit/categories/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });
      const data = await categories.json();
      if (data) {
        setAllAffidavitCategory(data);
      }
    };
    fetchAffidavitCategories();
  }, []);

  /*----------------Functionality for tuggling the user menu list----*/
  const [menuListOpen, setMenuListOpen] = useState(false);
  const toggleMenuList = () => {
    setMenuListOpen(!menuListOpen);
  };
  return (
    <AppContext.Provider
      value={{
        allCourts,
        allStates,
        allAffidavits,
        allAffidavitCategory,
        message,
        isAuthenticated,
        user, // from the google firebase authentication
        loggedInUser, // user stored in the django database.
        userToken,
        menuListOpen,
        showSidebar,

        setShowSidebar,
        closeSidebar,
        toggleSidebar,
        // handleGoggleLogin,
        // handleFacebookLogin,
        handleEmailAndPasswordSignup,
        handleEmailAndPasswordLogin,
        handleForgotPassword,
        handleLogout,
        handleUpdatePassword,
        setMessage,
        toggleMenuList,
      }}
    >
      {loading ? <Loader /> : children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
