import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/aff-logo.png";

export const AccountNavigation = () => {
  return (
    <nav className="navbar acc">
      <Link to="/">
        <img
          src={logo}
          alt=""
          style={{ width: "15rem", height: "2.5rem", cursor: "pointer" }}
        />
      </Link>
    </nav>
  );
};

//hi@flutterwavego.com
