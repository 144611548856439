export const Template = ({ displayName, animation }) => {
  return (
    <div className="template">
      <div className="hd">
        <span className="hd-1"></span>
        <span className="hd-2"></span>
        <span className="hd-3"></span>
      </div>
      <div className={`${animation ? "animated-element" : ""}`}>
        <h3>{displayName}</h3>
        <div className="bd">
          <span className="bd-cont"></span>
          <span className="bd-cont"></span>
          <span className="bd-cont"></span>
          <span className="bd-cont"></span>
          <span className="bd-cont"></span>
          <span className="bd-cont"></span>
          <span className="bd-cont"></span>
          <span className="bd-cont"></span>
        </div>
      </div>
      <div className="dp">
        <span className="dp-rt"></span>
      </div>
      <div className="ct">
        <span className="ct-sw"></span>
        <span className="ct-sw"></span>
      </div>
      <div className="cm">
        <span className="cm-1"></span>
        <span className="cm-2"></span>
      </div>
    </div>
  );
};
