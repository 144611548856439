import { useState } from "react";
import SignUp from "../../component/SignUp";
import Login from "../../component/SignIn";
import { useSearchParams } from "react-router-dom";
import { AccountNavigation } from "../../component/AccountNavigation";

const AuthHome = () => {
  const [queryParameters] = useSearchParams();
  const source = queryParameters.get("redirect-src");
  const [loginActive, setLoginActive] = useState(true);

  const toggleActiveForm = () => {
    setLoginActive(!loginActive);
  };

  return (
    <>
      <AccountNavigation />
      {loginActive ? (
        <Login toggle={toggleActiveForm} from={source} />
      ) : (
        <SignUp toggle={toggleActiveForm} from={source} />
      )}
    </>
  );
};

export default AuthHome;
