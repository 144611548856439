import React from "react";

export const Privacy = () => {
  return (
    <section style={{ fontSize: 16 }}>
      <h1 className="heading-tertiary">Privacy Policy</h1>
      <div>
        Please read our Privacy Policy before using the affidocs.com platform.
        By using any part of the Affidocs.com website/application, you
        acknowledge and agree to the processing of your personal data under the
        terms and conditions set out in this privacy policy.
        <h3> 1.0 Privacy Rights</h3>
        <p>
          This Privacy Policy describes your privacy rights regarding how we
          collect, use, store, share and protect your personal information. It
          applies to the Affidocs platform (which comprises the affidocs.com
          website), its applications, services, tools and content regardless of
          how you access or use them.{" "}
        </p>
        <h3>2.0 Consent You accept this Privacy Policy</h3>
        <p>
          when you sign up for, access, or use our products, services, content,
          features, technologies or functions offered on our Affidocs platform,
          all related sites, applications, and services (collectively known as
          “Affidocs” or the “Platform”). This Policy governs the use of Affidocs
          by registered and non-registered users, unless otherwise agreed
          through a written contract. We may amend this Privacy Policy at any
          time (and without recourse to you) by posting a revised version on our
          Platform and/or through email notification. The revised version will
          be effective immediately after posting or any other date as may be
          announced by us.
        </p>
        <h3>3.0 Your Personal Information </h3>
        <ul>
          <li>
            3.1 When you use Affidocs services either as a registered or
            non-registered user, we collect information sent to us by your
            computer, mobile phone, Point of Sale (“POS”) terminal or other
            electronic access devices. The information shall be collected
            automatically.
          </li>
          <li>
            3.2 We may also collect information you directly provide to us
            including but not limited to information on web forms, account
            update information and correspondence with Affidocs support
            services. We also collect information about your transactions and
            your activities. In addition, we collect your contact information
            such as; name, address, phone number, email, etc.
          </li>
          <li>
            3.3 We may also use information provided by third parties like
            social media sites and verification agencies. Information about you
            which are provided by you or any person to other websites or
            platforms are not controlled by Affidocs, and we are therefore not
            liable for how they use it.
          </li>
          <li>
            3.4 You can also let us know if you feel that your personal
            information is being processed in any way other than how you have
            consented to it being used. We will take reasonable steps to ensure
            that your personal information is processed in the manner in which
            you have consented to it, or we may delete it based on your
            instruction. However, we may not accede to your request if it is
            unrealistic, impractical or inimical to our compliance with
            applicable regulation or laws.
          </li>
        </ul>
        <h3>4.0 What we do with your personal</h3>
        <p>
          information Our purpose for collecting your personal information is to
          give you an efficient, enjoyable and secure customer experience. We
          may use your information to:{" "}
        </p>
        <ul>
          <li>4.1 Provide Affidocs Services and support;</li>
          <li>
            4.2 Process transactions, and send notices about your transactions
            to requisite parties;
          </li>
          <li>
            4.3 Contact you at any time through your provided telephone number,
            email address or other contact details;
          </li>
          <li>
            4.4 Notify you of new products, services and/or offerings; or any
            enhancements/amendments to existing products services and/or
            offerings.
          </li>
        </ul>
        <h3>5.0 Cookies </h3>
        <ul>
          <li>
            5.1 Cookies are small files placed on your computer's hard drive
            that enables the platform or website to identify your computer as
            you view different pages. Cookies allow websites and applications to
            store your preferences in order to present content, options or
            functions that are specific to you. Like most interactive websites,
            and online platforms, Affidocs uses cookies to enable the tracking
            of your activity for the duration of a session. Affidocs uses only
            encrypted session cookies which are erased either after a predefined
            timeout period or once the user logs out of Affidocs and closes the
            browser. Session cookies do not collect information from the user's
            computer. They will typically store information in the form of a
            session identification that does not personally identify the user.
          </li>
          <li>
            5.2 We do not have access or control over the cookies of our
            partners, affiliates, and service providers. We are therefore not
            responsible for their use of their cookies.
          </li>
        </ul>
        <h3> 6.0 How we protect your personal information </h3>
        <ul>
          <li>
            6.1 We store and process your personal information on our computers
            in Nigeria, Africa and elsewhere in the world where our facilities
            are located. Data protection laws vary among countries, with some
            providing more protection than others. Regardless of where your
            information is processed, we apply the same protections described in
            this policy. You have a right (upon your request) to be informed of
            the appropriate safeguards for data protection in the foreign
            country.{" "}
          </li>
          <li>
            6.2 We protect your information using physical, technical, and
            administrative security measures to reduce the risks of loss,
            misuse, unauthorized access, disclosure and alteration. Some of the
            safeguards we use are firewalls and data encryption, and information
            access authorization controls.
          </li>
        </ul>
        <h3> 7.0 Access to Your Data </h3>
        <ul>
          <li>
            7.1 If you are a registered user on Affidocs, you may access your
            personal information using your secure login credentials to access
            the relevant system features for such purpose.{" "}
          </li>
        </ul>
        <h3>8.0 How We Share your information with other Relevant Parties </h3>
        <ul>
          <li>
            8.1 During your transaction, we may provide the corresponding party
            with information such as your name, phone number or other
            information needed to provide the services for which we aligned with
            the third party
          </li>
          <li>
            8.2 You accept that your pictures and testimonials on all social
            media platforms about Affidocs can be used for limited promotional
            purposes by Technox Solutions or its agents. This does not include
            your trademark or copyright protected materials.
          </li>
          <li>
            8.3 From time to time we may send you relevant information such as
            product updates, new functionalities, tips on how to use Affidocs
            and new service offerings.
          </li>
          <li>
            8.4 We may also share your personal information in compliance with
            local or international regulatory requirements, with fraud
            prevention and risk management agencies, with our service providers
            or in compliance with a court order.
          </li>
        </ul>
        <h3>9.0 Links to Other Websites </h3>
        <ul>
          <li>
            9.1 Certain transaction processing channels on Affidocs may require
            links to other websites. Please note that Technox solutions is not
            responsible for, and has no control over third party websites. We do
            not monitor or review the content of other parties’ websites which
            may be linked from this website. Opinions expressed or materials
            appearing on such websites are not necessarily shared or endorsed by
            us, and Technox Solutions should not be regarded as the publisher of
            such opinions or materials, and you agree that we shall not be
            liable for any liability arising from your interpretation of any
            information on any third-party website linked to Affidocs
          </li>
          <li>
            9.2 We reserve the right to modify our Policy at any time and
            without any prior notice to you. Ensure you check this Policy
            regularly to stay updated with any amendment and understand how we
            use your information.
          </li>
        </ul>
        <h3>10. Limitation of Liability </h3>
        <ul>
          <li>
            10.1 Technox solutions will not be liable for loss of income,
            profits, business, opportunity, goodwill, contracts or any indirect,
            special, incidental or consequential damages arising out of or in
            connection with the Service, any computation or failed transaction
            on Affidocs, arising out of any breach of this Policy.{" "}
          </li>
          <li>
            10.2 We will not be liable for any loss or damage arising as a
            result of unauthorized access to the Service if (a) you
            intentionally or negligently fail to take reasonable precautions to
            protect your security, access codes, login details or any device
            used to access the Service, or (b) you acted fraudulently.{" "}
          </li>
          <li>
            10.3 Our liability to you or any third party in any circumstance of
            proven liability by us, shall not exceed the fees paid to us in
            respect of the specific transaction that gave rise to the claim or
            liability, unless otherwise specified by a court of competent
            jurisdiction.
          </li>
        </ul>
      </div>
    </section>
  );
};
