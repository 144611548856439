import { Button } from "@mui/material";
import { AffidavitForm } from "../component/AffidavitForm";
import EditIcon from "@mui/icons-material/Edit";

export const UserCanGenerateAffidavit = ({
  fieldNames,
  fieldValues,
  questions,
  step,
  setStep,
  goPreviousStep,
  goNextStep,
  onSubmit,
  onChange,
  handleDayChange,
  handleMonthChange,
  handleYearChange,
  day,
  month,
  year,
  handleDay2Change,
  handleMonth2Change,
  handleYear2Change,
  day2,
  month2,
  year2,
  message,
  setMessage,
}) => {
  const checkInput = (e) => {
    e.preventDefault();
    goNextStep();
  };

  return (
    <div className="fl-col container">
      <div className="aff-form">
        <div>{message && <p className="message error">{message}</p>}</div>
        <form onSubmit={(e) => checkInput(e)}>
          {questions.map((question, index) => {
            return (
              step === index && (
                <div key={index}>
                  <div className="question-counter">
                    {index + 1}/{questions.length}
                  </div>
                  {/* some questions depend on the answer to the previous question> Those question contain the tag "linked" in the database */}
                  <AffidavitForm
                    index={index}
                    question={question}
                    fieldNames={fieldNames}
                    fieldName={fieldNames[index]}
                    fieldValues={fieldValues}
                    fieldValue={fieldValues[fieldNames[index]]}
                    immediatePreviousFieldValue={
                      fieldValues[fieldNames[index - 1]]
                    }
                    onChange={onChange}
                    handleDayChange={handleDayChange}
                    handleMonthChange={handleMonthChange}
                    handleYearChange={handleYearChange}
                    day={day}
                    month={month}
                    year={year}
                    handleDay2Change={handleDay2Change}
                    handleMonth2Change={handleMonth2Change}
                    handleYear2Change={handleYear2Change}
                    day2={day2}
                    month2={month2}
                    year2={year2}
                    questions={questions}
                    setMessage={setMessage}
                  />
                </div>
              )
            );
          })}
        </form>
      </div>
      <div className="fl-row-mb question-navigator">
        {step > 0 && step !== questions.length && (
          <span>
            <Button
              variant="outlined"
              type="button"
              onClick={() => goPreviousStep()}
            >
              Back
            </Button>
          </span>
        )}
        {step < questions.length && (
          <span>
            <Button
              variant="contained"
              type="button"
              onClick={() => goNextStep()}
            >
              Next
            </Button>
          </span>
        )}
      </div>
      {step === questions.length && (
        <div className="preview">
          <div className="preview__txt">
            <h3 style={{ border: "1px solid green", padding: 5, fontSize: 15 }}>
              Please cross check your inputs. You won't be able to change these
              value after submission. If all is good, click on "GENERATE
              AFFIDAVIT" to continue.
            </h3>
          </div>
          <div className="preview__inputs">
            {questions.map((question, index) => {
              return (
                <div key={index} className="preview__inputs-items">
                  <div>{index + 1}</div>
                  <div>
                    <p className="text">
                      {question.split(":")[0].includes("[") &&
                      question.split(":")[0].includes("]")
                        ? question
                            .split(":")[0]
                            .trim()
                            .replace(
                              question
                                .split(":")[0]
                                .substring(
                                  question.split(":")[0].indexOf("["),
                                  question.split(":")[0].indexOf("]") + 1
                                )
                                .trim(),
                              fieldValues[
                                question
                                  .split(":")[0]
                                  .substring(
                                    question.split(":")[0].indexOf("[") + 1,
                                    question.split(":")[0].indexOf("]")
                                  )
                                  .trim()
                              ]
                            )
                        : question.split(":")[0].trim()}
                    </p>
                    <p className="value">
                      <span>{fieldValues[fieldNames[index]]}</span>
                      <button className="edit" onClick={() => setStep(index)}>
                        <EditIcon className="icon" />
                      </button>
                    </p>{" "}
                  </div>
                </div>
              );
            })}
          </div>
          <span>
            <button className="cta" type="submit" onClick={onSubmit}>
              Generate Affidavit
            </button>
          </span>
        </div>
      )}
    </div>
  );
};
