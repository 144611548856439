import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../util/context";
import { Link } from "react-router-dom";
import { AccountNavigation } from "../../component/AccountNavigation";

const PasswordReset = () => {
  const { handleForgotPassword, message, setMessage } = useGlobalContext();

  // Ensures there is no error message on page load
  useEffect(() => {
    setMessage("");
  }, [setMessage]);

  const [formData, setFormData] = useState({
    email: "",
  });

  //   distructure formData
  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const forgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await handleForgotPassword(email);
      if (response) {
      }
    } catch (err) {
      setMessage(err);
    }
  };

  return (
    <>
      <AccountNavigation />
      <section className="account-container">
        <article className="account">
          <div className="content">
            <div className="credential">
              <form className="form" onSubmit={(e) => forgotPassword(e)}>
                <h3>Enter your email to reser your password</h3>
                {message && (
                  <div className="account-message">
                    <h3 className="message">
                      {message && <span className="success">{message}</span>}
                    </h3>
                  </div>
                )}
                <div className="form__group">
                  <input
                    className="form__input"
                    //   type="email"
                    name="email"
                    value={email}
                    onChange={(e) => onChange(e)}
                    placeholder="Enter Emaill address"
                  />
                </div>

                {!email ? (
                  <div className="form__group">
                    <button
                      className="form__group btn-action disabled"
                      disabled
                    >
                      Reset
                    </button>
                  </div>
                ) : (
                  <div className="form__group btn-action">
                    <button className="cta">Reset</button>
                  </div>
                )}
              </form>
            </div>
            <div className="member">
              <Link to="/account/" className="join">
                Not a member yet? Sign up
              </Link>
            </div>
          </div>
        </article>
      </section>
    </>
  );
};

export default PasswordReset;
