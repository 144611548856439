import { useState, useEffect } from "react";
import { server } from "../config";
import { AffidavitResult } from "../component/AffidavitResult";
import { useGlobalContext } from "../util/context";
import Checkout from "../component/Checkout";
import { Link, useNavigate, useParams } from "react-router-dom";
// import { DisabledAccount } from "../component/DisabledAccount";
import { UserCanGenerateAffidavit } from "../component/UserCanGenerateAffidavit";
import { UserCanNotGenerateAffidavit } from "../component/UserCanNotGenerateAffidavit";
import { Loader } from "../component/Loader";
import { Template } from "../component/Template";

function Affidavit() {
  const { allCourts, userToken, loggedInUser } = useGlobalContext();

  const { slug } = useParams();
  const navigate = useNavigate();

  const [id, setID] = useState(null);
  const [affidavit, setAffidavit] = useState({});
  const [name, setName] = useState("");
  const [displayName, setDisplayName] = useState();
  const [content, setContent] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(null);
  // const [particulars, setParticular] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [fieldNames, setFieldNames] = useState([]);
  const [fieldsToBeCapitalized, setFieldsToBeCapitalized] = useState([]);

  const [ready, setReady] = useState(null);

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const [unlock, setUnlock] = useState(false);

  const [userOrders, setUserOrders] = useState(0);

  //-----------------fetch the affidavit whose category(slug) is in the url ---------------------
  useEffect(
    function () {
      async function getAffidavit() {
        const config = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        };
        try {
          const response = await fetch(
            `${server}/api/affidavit/${slug}/`,
            config
          );
          if (!response.ok) {
            throw new Error(
              `Something went wrong generating affidavit of ${slug}`
            );
          }
          if (response.status === 500) {
            throw new Error(
              `Something went wrong generating affidavit of ${slug}`
            );
          }
          const data = await response.json();
          setAffidavit(data);
          setID(data?.id);
          setName(data?.name); // The name that should show in affidavit.js url page
          setDisplayName(data?.display_name); // The name that needs to show in the order and downloaded document
          setContent(data?.content);
          setDescription(data?.description);
          setPrice(data?.price);
          setQuestions(data?.questions.split("?"));
          setReady(data.ready);
          setFieldsToBeCapitalized(data?.capitalize_inputs.split(","));
          setFieldNames(data?.form_fields.replace(/ /g, "").split(","));
          setLoading(false);
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      }
      getAffidavit();
    },
    [slug, userToken]
  );
  //-----------------fetch user pending orders ---------------------
  const [userPendingOrder, setUserPendingOrder] = useState(false);
  const [showStartButton, setShowStartButton] = useState(true);

  const fetchOrders = async () => {
    const config = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    try {
      const response = await fetch(
        `${server}/api/order/pending/Pending/`,
        config
      );
      const data = await response.json();
      if (data.length === 1) {
        setUserPendingOrder(true);
      } else if (data.length >= 2) {
        setUserOrders(data.length);
      }
    } catch (error) {}
  };

  //-----------------start the input form --------------------------
  const startForm = () => {
    setShowStartButton(false);
    fetchOrders();
  };

  // Function to count the length of input entered by user
  function getCount(str) {
    return str.split(" ").filter(function (num) {
      return num !== "";
    }).length;
  }

  // Function to validate full name and address enetered by user
  const validateInputLength = (field, input) => {
    const states = [
      "abuja",
      "abia",
      "adamawa",
      "akwa ibom",
      "anambra",
      "bauchi",
      "bayelsa",
      "benue",
      "borno",
      "cross river",
      "delta",
      "ebonyi",
      "edo",
      "ekiti",
      "enugu",
      "gombe",
      "imo",
      "jigawa",
      "kaduna",
      "kano",
      "katsina",
      "kebbi",
      "kogi",
      "kwara",
      "lagos",
      "nassarawa",
      "niger",
      "ogun",
      "ondo",
      "osun",
      "oyo",
      "plateau",
      "rivers",
      "sokoto",
      "taraba",
      "yobe",
      "zamfara",
    ];
    const stateEnteredByUser = input.toLowerCase().split(" ")[
      input.toLowerCase().split(" ").length - 2
    ];

    if (field === "fname") {
      const lengOfInput = getCount(input);
      if (lengOfInput < 2) {
        setMessage("Enter your full name.");
        return false;
      }
    }
    if (field === "address") {
      const lengOfInput = getCount(input);
      if (lengOfInput < 6) {
        setMessage("Follow the format specified for a proper address.");
        return false;
      }
      if (!parseInt(input.split(" ")[0].substring(0, 1))) {
        setMessage("Address must contain house number.");
        return false;
      }
      if (
        !input.toLowerCase().includes("state") ||
        !states.includes(stateEnteredByUser)
      ) {
        setMessage(
          "Address must contain state and written in the format specified"
        );
        return false;
      }
    }
    return true;
  };

  const capitalizeInputs = (field, words, courtInfo) => {
    for (let fieldName of fieldsToBeCapitalized) {
      if (fieldName.trim() === field.trim()) {
        const arrayOfWords = words.split(" ");
        for (let i = 0; i < arrayOfWords.length; i++) {
          if (typeof arrayOfWords[i] !== Number) {
            arrayOfWords[i] =
              arrayOfWords[i][0].toUpperCase() + arrayOfWords[i].substr(1);
          }
        }
        return arrayOfWords.join(" ");
      } else if (field === "cts") {
        return courtInfo.law;
      }
    }
    return words;
  };

  // Generate payment reference
  const [payment_reference, setPaymentReference] = useState();
  useEffect(() => {
    const generateref = async () => {
      try {
        const response = await fetch(`${server}/api/order/ref/`);
        const reference = await response.json();
        if (response.status === 200) {
          setPaymentReference(reference.ref);
        }
      } catch (error) {}
    };
    generateref();
  }, []);

  //-----------------show the result -------------------------------
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  const openResultModal = () => {
    setIsResultModalOpen(true);
  };

  //-------------------------open checkout-------------------------
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
  const openCheckout = () => {
    setIsResultModalOpen(false);
    setIsCheckoutOpen(true);
  };

  //--------------Initial values of the form fields--------------------------------
  useEffect(() => {
    // The array of field names is converted to object of fieldValues
    // in order to get the initial field values of the various form fields
    // .
    setFieldValues(
      fieldNames.reduce((array, value) => ({ ...array, [value]: "" }), {})
    );
  }, [fieldNames]);

  const [fieldValues, setFieldValues] = useState([]);

  const onChange = (e) =>
    setFieldValues({ ...fieldValues, [e.target.name]: e.target.value });

  //--------------Form navigation buttons-------------------------------------------
  const [step, setStep] = useState(0); // This serves the navigation purpose

  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  // Event handlers for select elements
  const handleDayChange = (event) => {
    setDay(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const dateValue1 =
    day > 3 && day < 31
      ? `${day}th ${month}, ${year}`
      : `${month} ${day}, ${year}`;

  const [day2, setDay2] = useState("");
  const [month2, setMonth2] = useState("");
  const [year2, setYear2] = useState("");

  const handleDay2Change = (event) => {
    setDay2(event.target.value);
  };

  const handleMonth2Change = (event) => {
    setMonth2(event.target.value);
  };

  const handleYear2Change = (event) => {
    setYear2(event.target.value);
  };
  const dateValue2 =
    day2 > 3 && day2 < 31
      ? `${day2}th ${month2}, ${year2}`
      : `${month2} ${day2}, ${year2}`;

  const validateDate = (date) => {
    const dateArray = date.split(" ");
    if (dateArray[0] === "" || dateArray[1] === "" || dateArray[2] === "") {
      setMessage("Enter a complete date");
      return false;
    }
    return true;
  };

  function goNextStep() {
    setMessage("");
    if (step === questions.length) return; // To prevent state going beyound the maximum value
    if (fieldNames[step]?.slice(0, 4) === "date") {
      if (validateDate(dateValue1)) {
        setFieldValues({
          ...fieldValues,
          [fieldNames[step]]: dateValue1,
        });
      }
    }
    if (fieldNames[step]?.slice(0, 5) === "date2") {
      if (validateDate(dateValue2)) {
        setFieldValues({
          ...fieldValues,
          [fieldNames[step]]: dateValue2,
        });
      }
    }
    if (fieldNames[step] === "idNo" && fieldValues[fieldNames[step]] !== "") {
      setFieldValues({
        ...fieldValues,
        [fieldNames[step]]: `with identification No. ${
          fieldValues[fieldNames[step]]
        }`,
      });
    }
    if (fieldNames[step] === "fname" || fieldNames[step] === "address") {
      if (
        !validateInputLength(fieldNames[step], fieldValues[fieldNames[step]])
      ) {
        return;
      }
    }
    if (
      fieldNames[step]?.slice(0, 4) !== "date" &&
      fieldValues[fieldNames[step]] !== ""
    ) {
      setStep((step) => step + 1);
    } else if (
      fieldNames[step].slice(0, 4) === "date" &&
      day !== "" &&
      month !== "" &&
      year !== ""
    ) {
      setStep((step) => step + 1);
    } else if (
      fieldNames[step].slice(0, 5) === "date2" &&
      day2 !== "" &&
      month2 !== "" &&
      year2 !== ""
    ) {
      setStep((step) => step + 1);
    } else if (
      fieldNames[step] === "idNo" &&
      (fieldNames[step] === "" || fieldNames[step] !== "")
    ) {
      setStep((step) => step + 1);
    }
  }

  function goPreviousStep() {
    if (step === 0) return;
    setStep((step) => step - 1);
  }

  useEffect(() => {
    if (isResultModalOpen) {
      setTimeout(() => {
        setUnlock(true);
      }, 4000);
    }
  }, [isResultModalOpen]);
  //-------------------------submit form logic--------------------------------------------
  const product_name = id; // already captured in line 15
  const [court_title_1, setCourtTitle1] = useState("");
  const [court_title_2, setCourtTitle2] = useState("");
  const [court_title_3, setCourtTitle3] = useState("");
  const [court_city, setCourtCity] = useState("");
  const [court_type, setCourtType] = useState("");
  const [formattedBody, setFormattedBody] = useState([]);
  const [rawBody, setRawBody] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    // search allCourts, find the court whose name matches 'cts' field.
    // When that is found, destructure the various fields and save them individually
    // in a different state for onward passage to the Checkout component.
    const courtdetails = allCourts?.find(
      (court) => court.name.toLowerCase() === fieldValues["cts"].toLowerCase()
    );

    const affidavit_body = content.replace(/#([^#]+)#/g, (match, key) => {
      return fieldValues[key] !== undefined
        ? capitalizeInputs(key, fieldValues[key], courtdetails)
        : "";
    });

    setRawBody(affidavit_body);
    setFormattedBody(affidavit_body.replaceAll("undefined", "").split(":"));

    setCourtTitle1(courtdetails?.heading_1);
    setCourtTitle2(courtdetails?.heading_2);
    setCourtTitle3(courtdetails?.heading_3);
    setCourtCity(courtdetails?.city);
    setCourtType(courtdetails?.court_type);

    openResultModal();
    setFieldValues([]);
    setStep(0);
    setShowStartButton(true);
  };

  if (!userToken) {
    navigate(`/account?redirect-src=/affidavit/${slug}`);
  }
  return (
    <>
      <div className="fl-col aff">
        {userToken && loading && <Loader />}
        {error && !affidavit && (
          <section className="aff-section">
            <h3 className="heading-tertiary">
              Failed to process Affidavit of{" "}
              <span style={{ fontStyle: "italic", color: "orange" }}>
                "{slug.replaceAll("-", " ")}"
              </span>
            </h3>
            <p className="paragraph-primary">
              Is that a real affidavit you want to generate? Let's get it done
              for you within 30 munites
            </p>
            <Link to={`/document?cat=${slug}&&src=aff`} className="cta link">
              How it works
            </Link>
          </section>
        )}
        <section className="fl-row aff-section">
          {affidavit && (
            <article className="wd-4 aff-section__item aff-section__item--1">
              <div>
                <h1 className="heading-secondary">{name}</h1>
                <p className="paragraph-primary">{description}</p>
              </div>
            </article>
          )}
          <article className="wd-5 aff-section__item aff-section__item--2">
            {showStartButton && ready && userToken && (
              <>
                <Template displayName={displayName} />
                <div className="action">
                  <button onClick={() => startForm()} className="cta">
                    Get started
                  </button>
                </div>
              </>
            )}
            {userOrders < 2 && !showStartButton && ready && (
              <div className="aff-section__item-inner">
                <UserCanGenerateAffidavit
                  fieldNames={fieldNames}
                  fieldValues={fieldValues}
                  questions={questions}
                  step={step}
                  setStep={setStep}
                  message={message}
                  goPreviousStep={goPreviousStep}
                  goNextStep={goNextStep}
                  onSubmit={onSubmit}
                  onChange={onChange}
                  handleDayChange={handleDayChange}
                  handleMonthChange={handleMonthChange}
                  handleYearChange={handleYearChange}
                  day={day}
                  month={month}
                  year={year}
                  handleDay2Change={handleDay2Change}
                  handleMonth2Change={handleMonth2Change}
                  handleYear2Change={handleYear2Change}
                  day2={day2}
                  month2={month2}
                  year2={year2}
                />
              </div>
            )}
            {userOrders >= 2 && (
              <div className="aff-section__item-inner">
                <UserCanNotGenerateAffidavit />
              </div>
            )}
          </article>

          {isResultModalOpen && (
            <>
              {!userPendingOrder ? (
                <>
                  <AffidavitResult
                    client={loggedInUser.id}
                    name={name} // The actual name of the product
                    displayName={displayName}
                    product_name={product_name} // id representation of the name
                    court_title_1={court_title_1}
                    court_title_2={court_title_2}
                    court_title_3={court_title_3}
                    court_city={court_city}
                    court_type={court_type}
                    formattedBody={formattedBody}
                    product_body={rawBody}
                    amount_paid={price}
                    source="affidavit"
                    payment_reference={payment_reference}
                    isResultModalOpen={isResultModalOpen}
                    setIsResultModalOpen={setIsResultModalOpen}
                  />
                  {unlock && (
                    <div className="unlock">
                      <button onClick={openCheckout} className="cta">
                        Download
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <AffidavitResult
                  userPendingOrder={userPendingOrder}
                  openCheckout={openCheckout}
                  client={loggedInUser.id}
                  name={name} // The actual name of the product
                  displayName={displayName}
                  product_name={product_name} // id representation of the name
                  court_title_1={court_title_1}
                  court_title_2={court_title_2}
                  court_title_3={court_title_3}
                  court_city={court_city}
                  court_type={court_type}
                  formattedBody={formattedBody}
                  product_body={rawBody}
                  amount_paid={price}
                  source="affidavit"
                  payment_reference={payment_reference}
                  isResultModalOpen={isResultModalOpen}
                  setIsResultModalOpen={setIsResultModalOpen}
                />
              )}
            </>
          )}

          {isCheckoutOpen && (
            <Checkout
              client={loggedInUser.id} //The client id
              clientName={loggedInUser.client_name} // The client name
              phone={loggedInUser.client_phone}
              email={loggedInUser.client_email}
              name={name}
              displayName={displayName}
              amount_paid={price}
              product_name={product_name} // product name in id(being a related field)
              court_title_1={court_title_1}
              court_title_2={court_title_2}
              court_title_3={court_title_3}
              court_city={court_city}
              court_type={court_type}
              product_body={rawBody}
              source="affidavit"
              payment_reference={payment_reference}
              isCheckoutOpen={isCheckoutOpen}
              setIsCheckoutOpen={setIsCheckoutOpen}
            />
          )}
        </section>
      </div>
    </>
  );
}

export default Affidavit;
