import { useState, useEffect } from "react";
import { server } from "../config";
// import { CategoryCard } from "../component/CategoryCard";
import { Link, useParams } from "react-router-dom";
// import { useGlobalContext } from "../util/context";
import { AffidavitNotFound } from "../component/AffidavitNotFound";
import { Loader } from "../component/Loader";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function Category() {
  const { slug } = useParams();

  const [category, setCategory] = useState(null);
  const [id, setId] = useState(null);
  const [affidavits, setAffidavits] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    const handleSelectStart = (e) => {
      e.preventDefault();
    };

    // Attach event listeners when the component mounts
    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("selectstart", handleSelectStart);

    // Remove event listeners when the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("selectstart", handleSelectStart);
    };
  }, []);

  //-----------------fetch the affidavits category whose slug is in the url ---------------------

  useEffect(() => {
    const getCategory = async () => {
      try {
        setLoading(true);
        setError("");
        const response = await fetch(
          `${server}/api/affidavit/category/${slug}/`
        );
        if (!response.ok) {
          throw new Error(
            `The system couldn't automatically process that affidavit`
          );
        }
        const data = await response.json();
        setCategory(data);
        setId(data.id);
        setLoading(false);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    getCategory();
  }, [slug]);

  useEffect(() => {
    const getAffidavitsInACategory = async () => {
      try {
        const response = await fetch(`${server}/api/affidavit/category/${id}/`);
        const data = await response.json();
        if (data) {
          setAffidavits(data);
        }
      } catch (error) {}
    };
    getAffidavitsInACategory();
  }, [id, slug]);

  const [index, setIndex] = useState(0);
  useEffect(() => {
    const lastIndex = affidavits.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, affidavits.length]);

  return (
    <>
      <div className="fl-col aff-category">
        {loading && <Loader />}
        {!error && !loading && category?.ready && (
          <section className="fl-row aff-category-inner">
            <article className="wd-5 aff-category-inner__item aff-category-inner__item--1">
              <div>
                <h1 className="heading-tertiary">{category?.category}</h1>
                <p className="paragraph-primary">{category?.description}</p>
              </div>
            </article>
            <article className="wd-5 aff-category-inner__item aff-category-inner__item--2">
              {loading && <Loader />}
              {affidavits?.map((affidavit) => {
                const { id, name, slug } = affidavit;
                return (
                  <Link to={`/affidavit/${slug}`} key={id} className="cta">
                    {name} &rarr;
                  </Link>
                );
              })}
            </article>
          </section>
        )}
        {!error && !loading && !category?.ready && (
          <section className="fl-row not-ready">
            <article className="wd-5 not-ready__text">
              <div>
                <h1 className="heading-tertiary">
                  Let's generate your{" "}
                  <span style={{ color: "orange" }}>{category?.category}</span>{" "}
                  for you in 30 minutes for just{" "}
                  <span style={{ color: "orange" }}>N1,500</span>
                </h1>
                <p className="paragraph-primary">
                  This affidavit is presently not automated. Our in-house
                  experts will get it written for you in just 30 minutes
                </p>
                <h3 className="heading-tertiary">How it works</h3>
                <ul>
                  <li>
                    <CheckCircleOutlineIcon className="icon" />{" "}
                    <span>Click the start chat button below</span>
                  </li>
                  <li>
                    <CheckCircleOutlineIcon className="icon" />{" "}
                    <span>
                      Provide the necessary details to write the affidavit
                    </span>
                  </li>
                  <li>
                    <CheckCircleOutlineIcon className="icon" />{" "}
                    <span>
                      The affidavit will be written and uploaded to your{" "}
                      <Link to="/user">account</Link> for your preview
                    </span>
                  </li>
                  <li>
                    <CheckCircleOutlineIcon className="icon" />{" "}
                    <span>Proceed to pay</span>
                  </li>
                  <li>
                    <CheckCircleOutlineIcon className="icon" />{" "}
                    <span>Download the affidavit</span>
                  </li>
                </ul>
              </div>
              <a href="https://wa.me/2347051129657" className="cta">
                {/* <WhatsAppIcon className="whatsapp-icon" /> */}
                start chat
              </a>
            </article>
            <article className="wd-5 not-ready__card">
              <div className="template">
                <div className="hd">
                  <span className="hd-1"></span>
                  <span className="hd-2"></span>
                  <span className="hd-3"></span>
                </div>
                <h3>{category?.category}</h3>
                <div className="bd">
                  <span className="bd-cont"></span>
                  <span className="bd-cont"></span>
                  <span className="bd-cont"></span>
                  <span className="bd-cont"></span>
                  <span className="bd-cont"></span>
                  <span className="bd-cont"></span>
                  <span className="bd-cont"></span>
                  <span className="bd-cont"></span>
                </div>
                <div className="dp">
                  <span className="dp-rt"></span>
                </div>
                <div className="ct">
                  <span className="ct-sw"></span>
                  <span className="ct-sw"></span>
                </div>
                <div className="cm">
                  <span className="cm-1"></span>
                  <span className="cm-2"></span>
                </div>
              </div>
            </article>
          </section>
        )}
        <section>
          {error && <AffidavitNotFound error={error} slug={slug} />}
        </section>
      </div>
    </>
  );
}

export default Category;
