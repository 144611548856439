import Close from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { server } from "../config";
import { useGlobalContext } from "../util/context";
import { formattedPrice } from "../util/formattedPrice";
import CheckIcon from "@mui/icons-material/Check";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
// import { usePaystackPayment } from "react-paystack";
import { PaystackButton } from "react-paystack";
// import logo from "../images/affidocs-logo.jpg";

export default function Checkout({
  client,
  clientName,
  phone,
  email,
  displayName,
  product_name,
  court_title_1,
  court_title_2,
  court_title_3,
  court_city,
  court_type,
  product_body,
  amount_paid,
  source,
  payment_reference,
  isCheckoutOpen,
  setIsCheckoutOpen,
}) {
  const { userToken } = useGlobalContext();
  const navigate = useNavigate();

  // Utility function to post order (completed/pending order)
  const postOrder = async (status) => {
    let postStatus = false;
    const order_status = status;
    const body = JSON.stringify({
      client,
      product_name,
      court_title_1,
      court_title_2,
      court_title_3,
      court_city,
      court_type,
      product_body,
      order_status,
      payment_reference,
      amount_paid,
    });
    let csrftoken = null;
    const cookieString = document.cookie.trim();
    if (cookieString) {
      csrftoken = cookieString
        .split("; ")
        .find((cookie) => cookie.startsWith("csrftoken="))
        ?.split("=")[1];
    }

    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
      ...(csrftoken && { "X-CSRFToken": csrftoken }),
      // "X-CSRFToken": csrftoken,
    });

    const url = `${
      source === "affidavit"
        ? `${server}/api/order/create/`
        : `${server}/api/order/update/${payment_reference}/`
    }`;
    const method = `${source === "affidavit" ? "POST" : "PUT"}`;

    const config = {
      method: method,
      headers: headers,
      credentials: "include",
      body: body,
    };

    try {
      const response = await fetch(url, config);

      if (response.status === 201) {
        postStatus = true;
      }
    } catch (error) {
      postStatus = false;
    }

    return postStatus;
  };

  const cancelCheckout = () => {
    if (source === "affidavit") {
      if (postOrder("Pending")) {
        setIsCheckoutOpen(false);
        navigate("/user");
      }
    }
    if (source === "order") {
      setIsCheckoutOpen(false);
      navigate("/user");
    }
  };

  const config = {
    reference: payment_reference,
    email: email,
    amount: amount_paid * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_KEY,
  };

  const handleSuccessAction = (reference) => {
    if (reference.status === "success" && reference.message === "Approved") {
      if (postOrder("Completed")) {
        setIsCheckoutOpen(false);
        navigate(`/reference/${reference.trxref}`);
      }
    } else {
      cancelCheckout();
    }
  };

  // you can call this function anything
  const handleCloseAction = () => {
    cancelCheckout();
  };

  const componentProps = {
    ...config,
    text: "Proceed",
    onSuccess: (reference) => handleSuccessAction(reference),
    onClose: handleCloseAction,
  };

  return (
    <div
      className={`${
        isCheckoutOpen ? "result modal-overlay show-modal" : "modal-overlay"
      }`}
    >
      <div className="close">
        <Close className="icon" onClick={() => cancelCheckout()} />
      </div>

      <div className="checkout">
        <div className="cart-item">
          <h3 className="heading-secondary">Item in your cart</h3>
          <ul>
            <li>
              <PictureAsPdfIcon className="icon" /> {displayName}
            </li>
          </ul>
          <div className="price">
            <span>NGN{formattedPrice(3000)}</span>{" "}
            <span>
              NGN{formattedPrice(amount_paid)}
              <CheckIcon className="icon" />
            </span>
          </div>
        </div>
        <div>
          <PaystackButton {...componentProps} />
        </div>
      </div>
    </div>
  );
}

// 5531886652142950, 564, 3310, 09/32, 12345 202310051359209JJyYPWw8D
